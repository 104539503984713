import { useEffect } from "react";
import { useState } from "react";
import { GetDataListFromServer } from "../../lib/apis";
import { GAME_PP_GAME_LIST_API, GAME_PP_USER_LOG_API } from "../../lib/config";
import { QUERY_PP_USER_LOGS } from "../../lib/query_objects";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  DatePicker,
  message,
  Row,
  Select,
  Spin,
  Table,
  Typography,
} from "antd";
import { moneyFormat, queryHandler } from "../../lib/globle_functions";
import dayjs from "dayjs";
import moment from "moment";

const { Text } = Typography;

export default function PPUserDetail({ data, closeDrawer }) {
  const { access_token, ui_size } = useSelector((state) => state.user);
  const [list, setList] = useState([]);
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState({
    target_date: "",
    game_id: "",
    type:"SL"
  });
  const [paging, setPaging] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });

  async function getGames() {
    setLoading(true);
    await GetDataListFromServer(
      `${GAME_PP_GAME_LIST_API}`,
      "something=something",
      access_token
    )
      .then(({ data: { games } }) => {
        if (games && games.all) {
          setGames(games.all);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getGames();
  }, []);

  async function getList(page_number, per_page, updateFilter) {
    await GetDataListFromServer(
      `${GAME_PP_USER_LOG_API}`,
      queryHandler(
        updateFilter
          ? { ...updateFilter, user_id: data.id, type: "SL" }
          : { ...filter, user_id: data.id, type: "SL" },
        QUERY_PP_USER_LOGS,
        {
          page_number,
          per_page,
        }
      ),
      access_token
    )
      .then(({ data: { data: dataList, total } }) => {
        updateFilter && setFilter(updateFilter);
        setPaging({ ...paging, total, page_number, per_page });
        setList(dataList);
        setLoading(false);
      })
      .catch((error) => {
        message.error(error?.response?.data?.error || "Connection Error");
        setLoading(false);
      });
  }

  function clearTransaction() {
    getList(paging.page_number, paging.per_page, {
      target_date: "",
      game_id: "",
    });
  }

  useEffect(() => {
    getList(paging.page_number, paging.per_page);
  }, []);

  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      render: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Game",
      dataIndex: "game_id",
      render: (game_id) =>
        games.find((game) => game.gameID === game_id)?.gameName,
    },
    {
      title: "Bit Amount",
      dataIndex: "bet",
      render: (bit_amount) => moneyFormat(bit_amount || 0),
    },
    {
      title: "Win Amount",
      dataIndex: "win_amount",
      render: (win_amount) => moneyFormat(win_amount || 0),
    },
  ];

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={24}>
          <Row gutter={[12, 2]}>
            <Col span={6}>
              <Row>
                <Col span={12}>
                  <Text>Date</Text>
                </Col>
                <Col span={12}>
                  <DatePicker
                    allowClear={false}
                    style={{ with: "100%" }}
                    format={"YYYY-MM-DD"}
                    value={
                      filter.target_date !== ""
                        ? dayjs(filter.target_date, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        target_date: e,
                      });
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row>
                <Col span={8}>
                  <Text>Target Game</Text>
                </Col>
                <Col span={16}>
                  <Select
                    showSearch
                    value={filter.game_id}
                    style={{ width: "100%" }}
                    options={games.map((value) => ({
                      label: value.gameName,
                      value: value.gameID,
                    }))}
                    onChange={(e) => setFilter({ ...filter, game_id: e })}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={12} justify={"end"} style={{ width: "100%" }}>
                <Col>
                  <Button
                    size={ui_size}
                    loading={loading}
                    type="primary"
                    onClick={() => clearTransaction()}
                  >
                    Clear
                  </Button>
                </Col>
                <Col>
                  <Button
                    size={ui_size}
                    loading={loading}
                    type="primary"
                    onClick={() => {
                      getList(paging.page_number, paging.per_page);
                    }}
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Table
            size={ui_size}
            style={{ marginTop: "12px" }}
            columns={columns}
            rowKey={(record) => record.id}
            pagination={{
              total: paging.total,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 50],
              onChange: (pageNumber, perPage) => {
                getList(pageNumber, perPage);
              },
            }}
            dataSource={list}
            loading={loading}
          />
        </Col>
      </Row>
    </Spin>
  );
}
