import { useEffect } from "react";
import { useState } from "react";
import { GetDataListFromServer } from "../../lib/apis";
import { GAME_SHAN_USER_LOG_API } from "../../lib/config";
import { QUERY_SHAN_USER_LOGS } from "../../lib/query_objects";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  DatePicker,
  message,
  Modal,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import { queryHandler } from "../../lib/globle_functions";
import dayjs from "dayjs";
import moment from "moment";
import ShanGameDetailUi from "./shan_detail_ui";

const { Text } = Typography;

export default function ShanUserLogs({ data, closeDrawer }) {
  const { access_token, ui_size } = useSelector((state) => state.user);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    target_date: "",
  });
  const [paging, setPaging] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });
  const [detail, setDetail] = useState(false);

  async function getList(page_number, per_page, updateFilter) {
    await GetDataListFromServer(
      `${GAME_SHAN_USER_LOG_API}`,
      queryHandler(
        updateFilter ? updateFilter : { ...filter, user_id: data.id },
        { ...QUERY_SHAN_USER_LOGS, user_id: data.id },
        {
          page_number,
          per_page,
        }
      ),
      access_token
    )
      .then(({ data: { data: dataList, total } }) => {
        updateFilter && setFilter(updateFilter);
        setPaging({ ...paging, total, page_number, per_page });
        setList(dataList);
        setLoading(false);
      })
      .catch((error) => {
        message.error(error?.response?.data?.error || "Connection Error");
        setLoading(false);
      });
  }

  function clearTransaction() {
    getList(paging.page_number, paging.per_page, {
      target_date: "",
    });
  }

  useEffect(() => {
    getList(paging.page_number, paging.per_page);
  }, []);

  const columns = [
    {
      title: "Room No",
      dataIndex: "room_no",
    },
    {
      title: "Win Type",
      dataIndex: "win_lose",
    },
    {
      title: "Amount",
      dataIndex: "win_amount",
      render: (_, record) =>
        record.win_amount ? record.win_amount : record.lose_amount,
    },
    {
      title: "Cut Percent",
      dataIndex: "win_cut_percent",
    },
    {
      title: "Date",
      dataIndex: "created_at",
      render: (created_at) => moment(created_at).format("HH:mm:ss DD-MM-YYYY"),
    },
    {
      title: "",
      dataIndex: "id",
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => setDetail({ ...record, user_data: data })}
          size={ui_size}
        >
          Detail
        </Button>
      ),
    },
  ];

  function detailHandler() {
    const data = detail ? JSON.parse(detail.data) : false;

    return !!data ? (
      <ShanGameDetailUi
        data={{ ...data, user_data: detail.user_data }}
      />
    ) : (
      <div></div>
    );
  }

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={24}>
          <Row gutter={[12, 2]}>
            <Col span={6}>
              <Row>
                <Col span={12}>
                  <Text>Date</Text>
                </Col>
                <Col span={12}>
                  <DatePicker
                    allowClear={false}
                    style={{ with: "100%" }}
                    format={"YYYY-MM-DD"}
                    value={
                      filter.target_date !== ""
                        ? dayjs(filter.target_date, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        target_date: e,
                      });
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={6}></Col>
            <Col span={12}>
              <Row gutter={12} justify={"end"} style={{ width: "100%" }}>
                <Col>
                  <Button
                    size={ui_size}
                    loading={loading}
                    type="primary"
                    onClick={() => clearTransaction()}
                  >
                    Clear
                  </Button>
                </Col>
                <Col>
                  <Button
                    size={ui_size}
                    loading={loading}
                    type="primary"
                    onClick={() => {
                      getList(paging.page_number, paging.per_page);
                    }}
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Table
            size={ui_size}
            style={{ marginTop: "12px" }}
            columns={columns}
            rowKey={(record) => record.id}
            pagination={{
              total: paging.total,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 50],
              onChange: (pageNumber, perPage) => {
                getList(pageNumber, perPage);
              },
            }}
            dataSource={list}
            loading={loading}
          />
        </Col>
      </Row>
      <Modal
        open={!!detail}
        onCancel={() => setDetail(false)}
        destroyOnClose={true}
        footer={null}
        width={1100}
        title="Shan Detail"
      >
        {detailHandler()}
      </Modal>
    </Spin>
  );
}
