import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getListFromServer } from "../lib/apis";
import { Button, Col, Drawer, Row, Table, Tag, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { GAME_TWOD_THREED_TWOD_ADMIN_LIST_API } from "../lib/config";
import TwoDSettingForm from "../components/game_twod_threed/twod_setting_form";
import dayjs from "dayjs";

export default function GameTwoDAdminPage() {
  const { permissions, access_token, ui_size } = useSelector(
    (state) => state.user
  );
  const [list, setList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  const timeArray = ["11:00:00", "12:01:00", "15:00:00", "16:30:00"];

  async function getDataList() {
    setLoading(true);
    await getListFromServer(GAME_TWOD_THREED_TWOD_ADMIN_LIST_API, access_token)
      .then(({ data }) => {
        setLoading(false);
        setList(
          timeArray.map((value) =>
            data.find((item) => item.target_time === value)
          )
        );
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getDataList();
  }, []);

  const columns = [
    {
      title: "Time",
      dataIndex: "target_time",
      align: "center",
    },
    {
      title: "User Can Start Bit",
      dataIndex: "bit_start_time",
      align: "center",
      render: (bit_start_time) => dayjs(bit_start_time).format("HH:mm:ss"),
    },
    {
      title: "User Stop Bit",
      dataIndex: "bit_end_time",
      align: "center",
      render: (bit_end_time) => dayjs(bit_end_time).format("HH:mm:ss"),
    },
    {
      title: "Is Auto Generate",
      dataIndex: "is_auto",
      align: "center",
      render: (is_auto) => <Tag>{is_auto ? "True" : "Same as Other"}</Tag>,
    },
    {
      title: "",
      render: (_, user) => (
        <Row gutter={16} justify={"end"}>
          <Col>
            <Button
              disabled={!permissions.includes("TWOD_THREED_CREATE")}
              onClick={() => {
                setEdit(user);
                setShowForm(true);
              }}
              size={ui_size}
              type="primary"
            >
              Edit
            </Button>
          </Col>
        </Row>
      ),
    },
  ];

  function closeDrawer(key) {
    if (key) {
      getDataList();
    }
    setEdit(false);
    setShowForm(false);
  }

  return (
    <>
      <Row gutter={[0, 16]} justify={"end"}>
        <Col>
          {permissions.includes("TWOD_THREED_CREATE") && list.length < 4 ? (
            <Button
              size={ui_size}
              type="primary"
              onClick={() => setShowForm(true)}
              icon={<PlusOutlined />}
            >
              New 2D
            </Button>
          ) : (
            <div></div>
          )}
        </Col>
        <Col span={24}>
          <Table
            size={ui_size}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={list}
            loading={loading}
          />
        </Col>
      </Row>
      <Drawer
        open={showForm}
        onClose={() => closeDrawer()}
        title={edit ? "Update 2D" : "Create 2D"}
        destroyOnClose
        placement="right"
        width={"700px"}
      >
        <TwoDSettingForm edit={edit} closeDrawer={closeDrawer} />
      </Drawer>
    </>
  );
}
