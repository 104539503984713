import { useEffect, useState } from "react";
import {
  CreateDataOnServer,
  GetBallWinCut,
  UpdateDateOnServer,
} from "../lib/apis";
import {
  GAME_BALL_WIN_CUT_CREATE_API,
  GAME_BALL_WIN_CUT_LIST_API,
  GAME_BALL_WIN_CUT_UPDATE_API,
} from "../lib/config";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  InputNumber,
  Modal,
  Row,
  Typography,
  message,
} from "antd";

const { Text } = Typography;

export default function GameBallWinCutPage() {
  const { access_token, permissions, ui_size } = useSelector(
    (state) => state.user
  );
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  async function getWinCut() {
    setLoading(true);
    GetBallWinCut(GAME_BALL_WIN_CUT_LIST_API, access_token)
      .then(({ data }) => {
        setList(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getWinCut();
  }, []);

  async function updateWinCut(data) {
    if (list.length > 0) {
      setLoading(true);
      UpdateDateOnServer(
        GAME_BALL_WIN_CUT_UPDATE_API,
        list[0].id,
        data,
        access_token
      )
        .then(() => {
          getWinCut();
          setShowModal(false);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.error || "Connection Error");
        });
    } else {
      setLoading(true);
      CreateDataOnServer(GAME_BALL_WIN_CUT_CREATE_API, data, access_token)
        .then(() => {
          getWinCut();
          setShowModal(false);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.error || "Connection Error");
        });
    }
  }

  return (
    <>
      <Row gutter={[0, 16]} justify={"space-around"}>
        <Col span={24}>
          <Row justify={"end"}>
            <Col>
              {permissions.includes("BALL_CREATE") && (
                <Button
                  type="primary"
                  size={ui_size}
                  onClick={() => setShowModal(true)}
                >
                  {list.length > 0 ? "Update" : "Create"}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
        <Col>
          <Text>
            ပြားရှုံး /ပြားနိုင် ဒိုင်ကြေး ={" "}
            {list.length > 0 ? list[0].bet_cut_percent : ""} %
          </Text>
        </Col>
        <Col>
          <Text>
            မောင်း ဒိုင်ကြေး ={" "}
            {list.length > 0 ? list[0].maung_cut_percent : ""} %
          </Text>
        </Col>
      </Row>
      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        destroyOnClose
        footer={null}
        title={
          list.length > 0 ? "Update Win Cut Amount" : "Create Win Cut Amount"
        }
      >
        <Form
          size={ui_size}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          name="win_cut_form"
          onFinish={updateWinCut}
          initialValues={list.length > 0 ? list[0] : {}}
        >
          <Form.Item
            name="bet_cut_percent"
            label="ပြားရှုံး / ပြားနိုင် ကြေး %"
            rules={[{ required: true }]}
          >
            <InputNumber min={0} max={100} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="maung_cut_percent"
            label="မောင်း ကြေး %"
            rules={[{ required: true }]}
          >
            <InputNumber min={0} max={100} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
