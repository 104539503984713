import { Card, Col, Row, Tag, Typography } from "antd";
import { moneyFormat } from "../../lib/globle_functions";

const { Text } = Typography;

export default function shanTable(room, state, is_do) {
  const movingPoints = [270, 50, 50, 270, 310];
  const rotateIndex = { 0: 5, 1: 6, 2: 7, 3: 8, 5: 1, 6: 2, 7: 3, 8: 4 };

  function tableBuilder() {
    const targetUser = room.players.find(
      (value) => value.id === room.user_data.id
    );

    if (targetUser && targetUser.player_index !== 4) {
      const targetIndex = rotateIndex[targetUser.player_index];

      return room.players
        .slice(targetIndex, 9)
        .concat(room.players.slice(0, targetIndex));
    }

    return room.players;
  }

  function playerBuilder(player) {
    const dealer = room.players.find(
      (value) => value && value.player_index === room.dealer_index
    );

    return (
      <div className="player_detail">
        <div className="player_bit">
          <Row justify={"center"} gutter={[0, 2]}>
            <Col span={8}>
              {player?.cards?.first_card && (
                <img
                  src={`./cards/${player.cards.first_card.card}.png`}
                  style={{ width: "48px" }}
                  alt="card"
                ></img>
              )}
            </Col>
            <Col span={8}>
              {player?.cards?.second_card && (
                <img
                  src={`./cards/${player.cards.second_card.card}.png`}
                  style={{ width: "48px" }}
                  alt="card"
                ></img>
              )}
            </Col>
            <Col span={8}>
              {player?.cards?.third_card && (
                <img
                  src={`./cards/${player.cards.third_card.card}.png`}
                  style={{ width: "48px" }}
                  alt="card"
                ></img>
              )}
            </Col>
            <Col span={24}></Col>
            <Col>
              <Tag
                style={{ width: "150px", textAlign: "center" }}
                color={
                  room?.dealer_index === player?.player_index
                    ? "#d0d315"
                    : "#1c2e7c"
                }
              >
                {room?.dealer_index === player?.player_index
                  ? `Dealer`
                  : `BET ${player?.bet}`}
              </Tag>
            </Col>
          </Row>
        </div>
        <Card styles={{ body: { padding: "8px" } }}>
          <Row justify={"center"}>
            <Col>
              <Text>{player?.info?.name}</Text>
            </Col>
            <Col span={24}></Col>
            <Col>
              <Text type="currency">{moneyFormat(player?.balance_origin)}</Text>
            </Col>
          </Row>
        </Card>
        {state === "end" && (
          <div className="player_result">
            <Row>
              <Col span={24}>
                {player.id === dealer.id ? (
                  <div></div>
                ) : (
                  <Tag
                    style={{ width: "150px", textAlign: "center" }}
                    color={player.is_win ? "#197434" : "#661624"}
                  >
                    {player.is_win ? "+" : "-"}{" "}
                    {player.is_win ? player.win_amount : player.lose_amount}
                  </Tag>
                )}
              </Col>
              <Col span={24}>
                {player.id === dealer.id ? (
                  <div></div>
                ) : (
                  <Tag style={{ width: "150px", textAlign: "center" }}>
                    {player?.win_order ? <Text style={{fontWeight:700,color:'red'}}>{player.win_order}</Text> : ""}{" "}
                    Final = {moneyFormat(player?.balance)}
                  </Tag>
                )}
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="shan_table">
      <div className="table"></div>
      <div
        className="player"
        id="chair_0"
        style={{ transform: "rotate(90deg)" }}
      >
        <div
          className="player_box"
          style={{ marginLeft: "250px", rotate: "270deg" }}
        >
          <Tag
            color="#345816"
            style={{
              fontSize: "1.5rem",
              textAlign: "center",
              lineHeight: "2.2rem",
              width: "150px",
            }}
          >
            {room.dealer_amount_origin}
          </Tag>
          {state === "end" && (
            <div>
              <Tag
                color={
                  room.dealer_amount_origin === room.dealer_amount
                    ? "#000000"
                    : room.dealer_amount_origin < room.dealer_amount
                    ? "#135323"
                    : "#491019"
                }
                style={{
                  width: "150px",
                  textAlign: "center",
                }}
              >
                {room.dealer_amount_origin === room.dealer_amount
                  ? ""
                  : room.dealer_amount_origin < room.dealer_amount
                  ? "+"
                  : "-"}
                {room.dealer_amount_origin === room.dealer_amount
                  ? 0
                  : room.dealer_amount_origin < room.dealer_amount
                  ? room.dealer_amount - room.dealer_amount_origin
                  : room.dealer_amount_origin - room.dealer_amount}
              </Tag>
              <Tag style={{ width: "150px", textAlign: "center" }}>
                Final = {moneyFormat(room.dealer_amount)}
              </Tag>
            </div>
          )}
        </div>
      </div>
      {tableBuilder().map((value, index) => (
        <div
          className="player"
          style={{
            transform: `rotate( ${90 - (index + 1) * 36}deg)`,
          }}
          id={`chair_${index + 1}`}
          key={index}
        >
          <div
            className="player_box"
            style={{
              marginLeft: `${movingPoints[index % 5]}px`,
              rotate: `${270 + (index + 1) * 36}deg`,
              top: `${
                index % 5 === 3 ? "-40px" : index % 5 === 0 ? "40px" : "0px"
              }`,
              left: `${
                index % 5 === 3 ? "-35px" : index % 5 === 0 ? "-35px" : "0px"
              }`,
            }}
          >
            {value && playerBuilder(value)}
          </div>
        </div>
      ))}
    </div>
  );
}
