import { Col, Menu, Row } from "antd";
import { useState } from "react";
import AgentDetail from "./detail_page";
import AgentTransactionPage from "./transaction";
import AgentGames from "./games";

export default function AgentDetailPage({ data, closeDrawer }) {
  const [key, setKey] = useState("detail");
  const items = [
    {
      label: "Detail",
      key: "detail",
    },
    {
      label: "Transactions",
      key: "transaction",
    },
    {
      label: "Games",
      key: "game",
    },
  ];

  function bodyHandler() {
    switch (true) {
      case key === "transaction":
        return <AgentTransactionPage data={data} closeDrawer={closeDrawer} />;
        break;
      case key === "game":
        return <AgentGames data={data} closeDrawer={closeDrawer} />;
        break;
      default:
        return <AgentDetail data={data} closeDrawer={closeDrawer} />;
        break;
    }
  }

  return (
    <Row>
      <Col span={24}>
        <Menu
          onClick={(e) => setKey(e.key)}
          selectedKeys={[key]}
          mode="horizontal"
          items={items}
        />
      </Col>
      <Col span={24} style={{ padding: "12px", overflowX: "hidden" }}>
        {bodyHandler()}
      </Col>
    </Row>
  );
}
