import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Switch,
  TimePicker,
  message,
} from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { CreateDataOnServer, UpdateDateOnServer } from "../../lib/apis";
import {
  GAME_TWOD_THREED_TWOD_ADMIN_CREATE_API,
  GAME_TWOD_THREED_TWOD_ADMIN_UPDATE_API,
} from "../../lib/config";
import dayjs from "dayjs";

export default function TwoDSettingForm({ edit, closeDrawer }) {
  const { ui_size, access_token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  async function submitHandler(value) {
    if (edit) {
      setLoading(true);
      await UpdateDateOnServer(
        GAME_TWOD_THREED_TWOD_ADMIN_UPDATE_API,
        edit.id,
        value,
        access_token
      )
        .then(() => {
          setLoading(false);
          closeDrawer(true);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.error || "Connection Error");
        });
    } else {
      setLoading(true);
      await CreateDataOnServer(
        GAME_TWOD_THREED_TWOD_ADMIN_CREATE_API,
        value,
        access_token
      )
        .then(() => {
          setLoading(false);
          closeDrawer(true);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.error || "Connection Error");
        });
    }
  }

  const TwoDTimeList = ["11:00:00", "12:01:00", "15:00:00", "16:30:00"];

  return (
    <Form
      variant="filled"
      size={ui_size}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      name="cms_agent_form"
      initialValues={
        edit
          ? {
              ...edit,
              bit_start_time: dayjs(edit.bit_start_time),
              bit_end_time: dayjs(edit.bit_end_time),
            }
          : {}
      }
      onFinish={submitHandler}
    >
      <Row justify={"end"}>
        <Col span={12}>
          <Form.Item
            name="target_time"
            label="Select Time"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              showSearch
              options={TwoDTimeList.map((tim) => ({ value: tim, label: tim }))}
            />
          </Form.Item>

          <Form.Item
            name="bit_start_time"
            label="User can Bit At"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TimePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="is_auto"
            label="Auto Generate ?"
            valuePropName="checked"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="bit_end_time"
            label="User Stop Bit At"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TimePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
