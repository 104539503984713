import {
  AimOutlined,
  CarOutlined,
  ClusterOutlined,
  DashboardOutlined,
  DollarOutlined,
  EuroCircleOutlined,
  FireOutlined,
  LaptopOutlined,
  SettingOutlined,
  ShoppingOutlined,
  TableOutlined,
  TagOutlined,
  TagsOutlined,
  TransactionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Typography } from "antd";
import { NavLink } from "react-router-dom";
const { Text } = Typography;

const MENU_ITEMS = [
  {
    key: "1",
    icon: <DashboardOutlined />,
    label: (
      <NavLink to="/">
        <Text>Dashboard</Text>
      </NavLink>
    ),
  },
  {
    key: "2",
    icon: <LaptopOutlined />,
    label: <Text>CMS</Text>,
    permission: [
      "CMS_USER_READ",
      "ACCESS_PERMISSION_READ",
      "UTILITY_AVATAR_READ",
    ],
    children: [
      {
        key: "2.1",
        permission: ["CMS_USER_READ"],
        link: "/cms/user",
        label: <NavLink to="/cms/user">CMS user</NavLink>,
      },
      {
        key: "2.2",
        permission: ["ACCESS_PERMISSION_READ"],
        link: "/cms/access_level",
        label: <NavLink to="/cms/access_level">Access Level</NavLink>,
      },
      {
        key: "2.3",
        permission: ["UTILITY_AVATAR_READ"],
        link: "/cms/avatar",
        label: <NavLink to="/cms/avatar">Avatar</NavLink>,
      },
    ],
  },
  {
    key: "3",
    icon: <ClusterOutlined />,
    label: <Text>Agents</Text>,
    permission: ["CMS_AGENT_READ"],
    children: [
      {
        key: "3.1",
        permission: ["CMS_AGENT_READ"],
        link: "/cms/agent",
        label: <NavLink to="cms/agent">Agents</NavLink>,
      },
    ],
  },
  {
    key: "4",
    icon: <DollarOutlined />,
    label: <Text>Subscription</Text>,
    permission: ["CMS_PAYMENT_METHOD_READ", "CMS_TRANSACTION_READ"],
    children: [
      {
        key: "4.1",
        permission: ["CMS_PAYMENT_METHOD_READ"],
        link: "/cms/payment_method",
        label: <NavLink to="cms/payment_method">Payment Methods</NavLink>,
      },
    ],
  },
  {
    key: "5",
    icon: <DollarOutlined />,
    link: "/payment_method",
    label: <NavLink to="/payment_method">Payment Method</NavLink>,
    permission: ["IS_AGENT"],
  },
  // {
  //   key: "6",
  //   icon: <CrownOutlined />,
  //   link: "/promotion",
  //   label: <NavLink to="/promotion">Promotion</NavLink>,
  //   permission: ["IS_AGENT"],
  // },
  // {
  //   key: "7",
  //   icon: <TrophyOutlined />,
  //   link: "/promotion_special",
  //   label: <NavLink to="/promotion_special">Promotion Special</NavLink>,
  //   permission: ["IS_AGENT"],
  // },
  // {
  //   key: "8",
  //   icon: <ShoppingOutlined />,
  //   link: "/subscription",
  //   label: <NavLink to="/subscription">Subscription</NavLink>,
  //   permission: ["IS_AGENT"],
  // },
  // {
  //   key: "9",
  //   icon: <AppstoreOutlined />,
  //   label: <Text>Utility</Text>,
  //   permission: ["UTILITY_AVATAR_READ"],
  //   children: [
  //     {
  //       key: "9.1",
  //       permission: ["UTILITY_AVATAR_READ"],
  //       link: "/cms/avatar",
  //       label: <NavLink to="/cms/avatar">Avatar</NavLink>,
  //     },
  //   ],
  // },
  {
    key: "10",
    icon: <TransactionOutlined />,
    label: <Text>Transaction</Text>,
    permission: ["IS_AGENT"],
    label: <NavLink to="/transaction">Transaction</NavLink>,
    noti: "transaction",
  },
  {
    key: "11",
    icon: <UserOutlined />,
    label: <Text>Users</Text>,
    permission: ["IS_AGENT"],
    label: <NavLink to="/users">Users</NavLink>,
    noti: "users",
  },
  {
    key: "12",
    icon: <CarOutlined />,
    label: <Text>2D/3D</Text>,
    permission: ["TWOD_THREED_READ"],
    children: [
      {
        key: "12.1",
        permission: ["TWOD_THREED_READ"],
        link: "/cms/games/twod_threed",
        label: <NavLink to="/cms/games/twod_threed">Agents</NavLink>,
      },
      {
        key: "12.2",
        permission: ["TWOD_THREED_READ"],
        link: "/cms/games/twod_admin",
        label: <NavLink to="/cms/games/twod_admin">2D Setting</NavLink>,
      },
      {
        key: "12.3",
        permission: ["TWOD_THREED_READ"],
        link: "/cms/games/twod_holiday",
        label: <NavLink to="/cms/games/twod_holiday">2D Holiday</NavLink>,
      },
      {
        key: "12.4",
        permission: ["TWOD_THREED_READ"],
        link: "/cms/games/twod_ongoing",
        label: <NavLink to="/cms/games/twod_ongoing">2D Ongoing</NavLink>,
      },
      {
        key: "12.5",
        permission: ["TWOD_THREED_READ"],
        link: "/cms/games/threed_ongoing",
        label: <NavLink to="/cms/games/threed_ongoing">Threed Ongoing</NavLink>,
      },
    ],
  },
  {
    key: "13",
    icon: <AimOutlined />,
    label: <Text>Ball</Text>,
    permission: ["BALL_READ"],
    children: [
      // {
      //   key: '13.1',
      //   permission: ['BALL_READ'],
      //   link: '/cms/games/ball_win_cut',
      //   label: <NavLink to="/cms/games/ball_win_cut">‌ဒိုင် ကြေး</NavLink>,
      // },
      // {
      //   key: "13.2",
      //   permission: ["BALL_READ"],
      //   link: "/cms/games/ball",
      //   label: <NavLink to="/cms/games/ball">Agents</NavLink>,
      // },
      {
        key: "13.3",
        permission: ["BALL_READ"],
        link: "/cms/games/ball_team",
        label: <NavLink to="/cms/games/ball_team">Teams</NavLink>,
      },
      {
        key: "13.4",
        permission: ["BALL_READ"],
        link: "/cms/games/ball_league",
        label: <NavLink to="/cms/games/ball_league">Leagues</NavLink>,
      },
      {
        key: "13.5",
        permission: ["BALL_READ"],
        link: "/cms/games/ball_match",
        label: <NavLink to="/cms/games/ball_match">Match</NavLink>,
      },
    ],
  },
  // {
  //   key: "14",
  //   icon: <FireOutlined />,
  //   label: <Text>Shan</Text>,
  //   permission: ["SHAN_READ"],
  //   children: [
  //     // {
  //     //   key: "14.1",
  //     //   permission: ["SHAN_READ"],
  //     //   link: "/cms/games/shan",
  //     //   label: <NavLink to="/cms/games/shan">‌Shan Komee</NavLink>,
  //     // },
  //   ],
  // },
  {
    key: "15",
    icon: <CarOutlined />,
    label: <Text>2D</Text>,
    permission: ["TWOD_THREED"],
    label: <NavLink to="/twod">2D</NavLink>,
  },
  {
    key: "16",
    icon: <EuroCircleOutlined />,
    label: <Text>3D</Text>,
    permission: ["TWOD_THREED"],
    label: <NavLink to="/threed">3D</NavLink>,
  },
  {
    key: "17",
    icon: <AimOutlined />,
    label: <Text>Ball Ongoing</Text>,
    permission: ["BALL"],
    label: <NavLink to="/ball_ongoing_deposit">Ball Ongoing</NavLink>,
  },
  {
    key: "18",
    icon: <TagOutlined />,
    label: <Text>Ball Body Lists</Text>,
    permission: ["BALL"],
    label: <NavLink to="/goal_forecast">Ball Body</NavLink>,
  },
  {
    key: "19",
    icon: <TagsOutlined />,
    label: <Text>Ball Maung Lists</Text>,
    permission: ["BALL"],
    label: <NavLink to="/maung_goal_forecast">Ball Maung</NavLink>,
  },
  {
    key: "20",
    icon: <TableOutlined />,
    label: <Text>Shan Tables</Text>,
    permission: ["SHAN"],
    label: <NavLink to="/shan_tables">Shan Tables</NavLink>,
  },
  {
    key: "200",
    icon: <SettingOutlined />,
    label: (
      <NavLink to="/settings">
        <Text>Setting</Text>
      </NavLink>
    ),
  },
];

export { MENU_ITEMS };
