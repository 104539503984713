import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  GetDataListFromServer,
  UpdateDateOnServer,
} from "../lib/apis";
import {
  Button,
  Col,
  Drawer,
  Popconfirm,
  Row,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  GAME_THREED_AGENT_ONGOING_LIST_API,
  GAME_THREED_AGENT_UPDATE_LIST_API,
} from "../lib/config";
import dayjs from "dayjs";
import ThreedEdit from "../components/game_twod_threed/threed_edit_form";
import ThreedDetailPage from "../components/game_twod_threed/threedDetail";
import { moneyFormat } from "../lib/globle_functions";

const { Text } = Typography;

export default function AgentThreedPage() {
  const { permissions, access_token, ui_size } = useSelector(
    (state) => state.user
  );
  const [list, setList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [detail, setDetail] = useState(false);
  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });

  async function getDataList(page_number, per_page) {
    setLoading(true);
    await GetDataListFromServer(
      GAME_THREED_AGENT_ONGOING_LIST_API,
      `page_number=${page_number}&per_page=${per_page}`,
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setList(
          result.map((value) => ({
            ...value,
            threed: JSON.parse(value.threed),
          }))
        );
        setPagin({ ...pagin, total, page_number, per_page });
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function block2d(id) {
    setLoading(true);
    await UpdateDateOnServer(
      GAME_THREED_AGENT_UPDATE_LIST_API,
      id,
      { close: true },
      access_token
    )
      .then(() => {
        getDataList(pagin.page_number, pagin.per_page);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getDataList(pagin.page_number, pagin.per_page);
  }, []);

  const columns = [
    {
      title: "Release Date",
      dataIndex: ["threed_round", "target_date"],
      align: "center",
      render: (date) => dayjs(date).format("YYYY-MM-DD"),
    },
    {
      title: "Bit start Date",
      dataIndex: ["threed_round", "bit_start_date"],
      align: "center",
      render: (date) => dayjs(date).format("YYYY-MM-DD"),
    },
    {
      title: "Bit End Date",
      dataIndex: ["threed_round", "bit_end_date"],
      align: "center",
      render: (date) => dayjs(date).format("YYYY-MM-DD"),
    },
    {
      title: "Is Finish / Block",
      dataIndex: "is_finish",
      align: "center",
      render: (is_finish) => (
        <Tag>{is_finish ? "Block By Agent or Finish" : "Ongoing"}</Tag>
      ),
    },
    {
      title: "",
      render: (_, user) => (
        <Row gutter={16} justify={"end"}>
          <Col>
            <Popconfirm
              title={`Are you sure Delet this Round ?`}
              onConfirm={() => {
                if (!user.is_finish) {
                  block2d(user.id);
                }
              }}
              disabled={user.is_finish}
              okText="Yes"
              cancelText="No"
            >
              <Button
                size={ui_size}
                disabled={user.is_finish}
                style={{ width: "100px" }}
                type="primary"
                danger
              >
                Delete
              </Button>
            </Popconfirm>
          </Col>
          <Col>
            <Button
              disabled={user.is_finish}
              onClick={() => {
                setEdit(user);
                setShowForm(true);
              }}
              size={ui_size}
              type="primary"
            >
              Edit
            </Button>
          </Col>
        </Row>
      ),
    },
  ];

  const expendableColumns = [
    {
      title: "Number",
      dataIndex: "key",
      align: "center",
      render: (key) => {
        switch (true) {
          case key < 10:
            return `00${key}`;
            break;
          case key < 100:
            return `0${key}`;
            break;
          default:
            return `${key}`;
            break;
        }
      },
    },
    {
      title: "Min Bit Amount",
      dataIndex: "min_bit",
      align: "center",
      render: (min_bit) => moneyFormat(min_bit),
    },
    {
      title: "Max Bit Amount",
      dataIndex: "max_bit",
      align: "center",
      render: (max_bit) => moneyFormat(max_bit),
    },
    {
      title: "Limit",
      dataIndex: "max",
      align: "center",
      render: (max) => moneyFormat(max),
    },
    {
      title: "Current Bit",
      dataIndex: "current",
      align: "center",
      render: (current) => moneyFormat(current),
    },
    {
      title: "",
      render: (_, record) => (
        <Button size={ui_size} type="primary" onClick={() => setDetail(record)}>
          Detail
        </Button>
      ),
    },
  ];

  function closeDrawer(key) {
    if (key) {
      getDataList(pagin.page_number, pagin.per_page);
    }
    setEdit(false);
    setShowForm(false);
  }

  return (
    <>
      <Row gutter={[0, 16]} justify={"end"}>
        <Col>
          {permissions.includes("TWOD_THREED_CREATE") && list.length < 5 && (
            <Button
              size={ui_size}
              type="primary"
              onClick={() => setShowForm(true)}
              icon={<PlusOutlined />}
            >
              New 2D
            </Button>
          )}
        </Col>
        <Col span={24}>
          <Table
            size={ui_size}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={list}
            loading={loading}
            pagination={{
              total: pagin.total,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 50],
              onChange: (pageNumber, perPage) => {
                getDataList(pageNumber, perPage);
              },
            }}
            expandable={{
              expandedRowRender: (record) => {
                return (
                  <div>
                    <Table
                      style={{ margin: "0" }}
                      columns={expendableColumns}
                      dataSource={record.threed
                        .sort((a, b) => b.current - a.current)
                        .map((value) => ({ ...value, ongoing_id: record.id }))}
                      rowKey={(threedRecord) =>
                        `${record.id}_${threedRecord.key}`
                      }
                    />
                  </div>
                );
              },
            }}
          />
        </Col>
      </Row>
      <Drawer
        open={showForm}
        onClose={() => closeDrawer()}
        title={"Update 3D results"}
        destroyOnClose
        placement="right"
        width={"800px"}
      >
        <ThreedEdit edit={edit} closeDrawer={closeDrawer} />
      </Drawer>
      <Drawer
        open={!!detail}
        onClose={() => setDetail(false)}
        title="Detail"
        destroyOnClose={true}
        placement="right"
        width={"800px"}
      >
        <ThreedDetailPage data={detail} closeDrawer={setDetail} />
      </Drawer>
    </>
  );
}
