import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetDataListFromServer } from "../lib/apis";
import { Button, Col, Row, Table, Typography, message } from "antd";
import { MoneyCollectFilled, PlusOutlined } from "@ant-design/icons";
import { AGENT_SUBSCRIPTION_LIST_API } from "../lib/config";
import { QUERY_CMS_TRANSACTION } from "../lib/query_objects";
import { moneyFormat, queryHandler } from "../lib/globle_functions";

const { Text } = Typography;

export default function AgentSubscriptionPage() {
  const { permissions, access_token, ui_size, id } = useSelector(
    (state) => state.user
  );
  const [list, setList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });
  const [filter, setFilter] = useState({
    payment_method_id: "",
    agent_id: id,
    order_by: "created_at",
    order_type: "DESC",
  });

  async function getDataList(page_number,per_page) {
    setLoading(true);
    await GetDataListFromServer(
      AGENT_SUBSCRIPTION_LIST_API,
      queryHandler(filter, QUERY_CMS_TRANSACTION, {page_number,per_page}),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setPagin({ ...pagin, total,page_number,per_page });
        setList(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getDataList(pagin.page_number,pagin.per_page);
  }, []);

  const columns = [
    {
      title: "Payment Method",
      dataIndex: ["payment_method", "name"],
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (_, record) => (
        <Text>{`${moneyFormat(record.amount)} ${record.currency}`}</Text>
      ),
    },
    {
      title: "Points",
      dataIndex: "points",
      render: (_, record) => (
        <Text>{`${record.addition ? "+" : "-"} ${record.points}`}</Text>
      ),
    },
    {
      title: "Transaction Date",
      dataIndex: "created_at",
    },
  ];

  return (
    <>
      <Row gutter={[0, 16]} justify={"end"}>
        <Col span={24}>
          <Table
            size={ui_size}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={list}
            loading={loading}
            pagination={{
              total: pagin.total,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 50],
              onChange: (pageNumber, perPage) => {
                getDataList(pageNumber, perPage);
              },
            }}
          />
        </Col>
      </Row>
    </>
  );
}
