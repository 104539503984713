import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetDataListFromServer, UpdateSingleDataOnServer } from "../lib/apis";
import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  InputNumber,
  Popconfirm,
  Row,
  Table,
  Typography,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  AGENT_PROMOTION_LIST_API,
  AGENT_PROMOTION_DELETE_API,
  AGENT_CREATE_USER_PROMO_API,
} from "../lib/config";
import { QUERY_AGENT_PROMOTION_DEPOSIT } from "../lib/query_objects";
import { queryHandler } from "../lib/globle_functions";
import AgentPromotionForm from "../components/agent_promotion/form";
import Modal from "antd/es/modal/Modal";
import { updateUser } from "../store/user";

const { Text } = Typography;

export default function AgentPromotionPage() {
  const { permissions, access_token, ui_size, first_time_promo, ...user } =
    useSelector((state) => state.user);
  const [list, setList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [modelLoading, setModelLoading] = useState(false);
  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    name: "",
    is_deleted: "false",
    order_by: "created_at",
    order_type: "DESC",
  });

  async function getDataList() {
    setLoading(true);
    await GetDataListFromServer(
      AGENT_PROMOTION_LIST_API,
      queryHandler(filter, QUERY_AGENT_PROMOTION_DEPOSIT, pagin),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setPagin({ ...pagin, total });
        setList(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function paymentMethodDelete(id) {
    setLoading(true);
    await UpdateSingleDataOnServer(AGENT_PROMOTION_DELETE_API, id, access_token)
      .then(() => {
        getDataList();
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getDataList();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Min Deposit Amount",
      dataIndex: "min_deposit_amount",
    },
    {
      title: "Max Deposit Amount",
      dataIndex: "max_deposit_amount",
    },
    {
      title: "Promotion Percentage",
      dataIndex: "points_percent",
      render: (points_percent) => <Text>{points_percent} %</Text>,
    },
    {
      title: "",
      render: (_, user) => (
        <Row gutter={16} justify={"end"}>
          <Col>
            <Popconfirm
              title="Are You Sure Delete This Payment Method ?"
              description="Delete can't recover"
              onConfirm={() => paymentMethodDelete(user.id)}
              okText="Yes"
              cancelText="No"
              disabled={!permissions.includes("IS_AGENT")}
            >
              <Button
                disabled={!permissions.includes("IS_AGENT")}
                size={ui_size}
                type="primary"
                danger
              >
                Delete
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  function closeDrawer(key) {
    if (key) {
      getDataList();
    }
    setShowForm(false);
  }

  async function updateFirstTime(value) {
    setModelLoading(true);
    UpdateSingleDataOnServer(
      AGENT_CREATE_USER_PROMO_API,
      value.point,
      access_token
    )
      .then(() => {
        dispatch(
          updateUser({
            ...user,
            permissions,
            ui_size,
            first_time_promo: value.point,
            access_token,
          })
        );

        setModelLoading(false);
        setShowModel(false);
      })
      .catch((error) => {
        setModelLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  return (
    <>
      <Row gutter={[0, 16]} justify={"space-between"}>
        <Col>
          <Card size="small">
            <Row gutter={24} align={"middle"}>
              <Col>
                <Text type="secondary">
                  First time Login Promotion : {first_time_promo || 0} Points
                </Text>
              </Col>
              <Col>
                <Button
                  size={ui_size}
                  onClick={() => setShowModel(true)}
                  type="primary"
                >
                  Edit
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col>
          {permissions.includes("IS_AGENT") && (
            <Button
              size={ui_size}
              type="primary"
              onClick={() => setShowForm(true)}
              icon={<PlusOutlined />}
            >
              New Promotion
            </Button>
          )}
        </Col>
        <Col span={24}>
          <Table
            size={ui_size}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={list}
            loading={loading}
          />
        </Col>
      </Row>
      <Drawer
        open={showForm}
        onClose={() => closeDrawer()}
        title="Create New Payment Method"
        destroyOnClose
        placement="right"
        width={"600px"}
      >
        <AgentPromotionForm closeDrawer={closeDrawer} />
      </Drawer>
      <Modal
        name="first_promo_model"
        open={showModel}
        footer={false}
        closable
        onCancel={() => setShowModel(false)}
        title="Edit First Time Log In Promotion Amount"
      >
        <Form name="first_time_promo" onFinish={updateFirstTime}>
          <Row style={{ marginTop: "24px" }} justify={"end"}>
            <Col span={24} style={{ marginBottom: "24px" }}>
              <Text>Current Amount : {first_time_promo || 0} Points</Text>
            </Col>
            <Col span={24}>
              <Form.Item
                name="point"
                label="Update Amount"
                rules={[{ required: true }]}
              >
                <InputNumber style={{ width: "100%" }} min={0} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button type="primary" loading={modelLoading} htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
