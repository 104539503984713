import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  CreateDataOnServer,
  GetDataListFromServer,
  UpdateSingleDataOnServer,
} from "../lib/apis";
import {
  UTILITY_AVATAR_CREATE_API,
  UTILITY_AVATAR_DELETE_API,
  UTILITY_AVATAR_LIST_API,
} from "../lib/config";
import {
  beforeUpload,
  queryHandler,
  uploadImages,
} from "../lib/globle_functions";
import { QUERY_UTILITY_AVATAR } from "../lib/query_objects";
import {
  Button,
  Card,
  Col,
  Modal,
  Popconfirm,
  Row,
  Upload,
  message,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

export default function UtilityAvatar() {
  const { permissions, access_token, ui_size } = useSelector(
    (state) => state.user
  );
  const [list, setList] = useState([]);
  const [showModel, setShowModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 1000,
    total: 0,
  });
  const [filter, setFilter] = useState({
    name: "",
    order_by: "created_at",
    order_type: "DESC",
  });
  const [imageUrl, setImageUrl] = useState("");

  async function uploadHandler() {
    if (imageUrl !== "") {
      setLoading(true);
      await CreateDataOnServer(
        UTILITY_AVATAR_CREATE_API,
        { url: imageUrl },
        access_token
      )
        .then(() => {
          setLoading(false);
          closeModel(true);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.error || "Connection Error");
        });
    }

    setImageUrl("");
    getDataList();
  }

  async function getDataList() {
    setLoading(true);
    await GetDataListFromServer(
      UTILITY_AVATAR_LIST_API,
      queryHandler(filter, QUERY_UTILITY_AVATAR, pagin),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setPagin({ ...pagin, total });
        setList(result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getDataList();
  }, []);

  function closeModel(refresh) {
    if (refresh) {
      getDataList();
    }
    setShowModel(false);
  }

  async function deleteAvatar(id) {
    setLoading(true);
    await UpdateSingleDataOnServer(UTILITY_AVATAR_DELETE_API, id, access_token)
      .then(() => {
        getDataList();
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  return (
    <>
      <Row gutter={[0, 16]} justify={"end"}>
        <Col>
          {permissions.includes("UTILITY_AVATAR_CREATE") && (
            <Button
              size={ui_size}
              onClick={() => setShowModel(true)}
              type="primary"
              icon={<PlusOutlined />}
            >
              Create Avatar
            </Button>
          )}
        </Col>
        <Col span={24}>
          <Row gutter={[24, 24]} justify={"space-around"}>
            {list.map((item) => (
              <Col flex={"200px"} key={item.id}>
                {permissions.includes("UTILITY_AVATAR_DELETE") ? (
                  <Popconfirm
                    title="Are You Sure!"
                    description="Delete Avatar for Users, Can't recover"
                    onConfirm={() => deleteAvatar(item.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <img
                      src={item.url}
                      alt="image"
                      style={{
                        height: "200px",
                        width: "200px",
                        cursor: "pointer",
                      }}
                    />
                  </Popconfirm>
                ) : (
                  <img
                    src={item.url}
                    alt="image"
                    style={{
                      height: "200px",
                      width: "200px",
                      cursor: "pointer",
                    }}
                  />
                )}
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Modal
        open={showModel}
        onCancel={() => closeModel()}
        destroyOnClose
        footer={null}
        title="Upload Avatar"
      >
        <Row gutter={[24, 24]} justify={"center"}>
          <Col>
            {/* <ImgCrop rotationSlider aspect={200 / 200}> */}
            <Upload
              name="avatar_upload"
              accept=".jpg,.jpeg,.png"
              listType="picture"
              showUploadList={false}
              disabled={loading}
              style={{ height: "200px", width: "200px" }}
              customRequest={(option) =>
                uploadImages({
                  ...option,
                  access_token,
                  folder_name: "avatar",
                  image: imageUrl,
                  setImage: setImageUrl,
                })
              }
              beforeUpload={(file) => beforeUpload(file, 10)}
              isImageUrl={true}
            >
              {imageUrl !== "" ? (
                <Card
                  styles={{
                    body: {
                      width: "200px",
                      height: "200px",
                      padding: "0px",
                    },
                  }}
                >
                  <img
                    style={{ height: "200px", width: "200px" }}
                    src={imageUrl}
                    alt="thumbnail"
                  />
                </Card>
              ) : (
                <button
                  style={{ border: 0, height: "200px", width: "200px" }}
                  type="button"
                >
                  {loading ? <LoadingOutlined /> : <PlusOutlined />}
                  <div style={{ marginTop: 8 }}>Upload</div>
                </button>
              )}
            </Upload>
            {/* </ImgCrop> */}
          </Col>
          <Col span={24}>
            <Row justify={"end"}>
              <Col>
                <Button
                  disabled={imageUrl === ""}
                  type="primary"
                  loading={loading}
                  onClick={() => uploadHandler()}
                >
                  Upload
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
