import { Layout } from "antd";
import LayoutSideBar from "./sidebar";
import LayoutHeader from "./header";
import LayoutBody from "./body";

export default function MainLayout() {
  return (
    <Layout>
      <LayoutSideBar />
      <Layout>
        <LayoutHeader />
        <LayoutBody />
      </Layout>
    </Layout>
  );
}
