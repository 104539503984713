import { Button, Col, Form, Input, Row, Select, message } from "antd";
import { useSelector } from "react-redux";
import { CreateDataOnServer } from "../../lib/apis";
import { AGENT_PAYMENT_METHOD_CREATE_API } from "../../lib/config";
import { useState } from "react";

export default function AgentPaymentMethodForm({ closeDrawer }) {
  const { ui_size, access_token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  async function submitHandler(value) {
    setLoading(true);
    await CreateDataOnServer(
      AGENT_PAYMENT_METHOD_CREATE_API,
      value,
      access_token
    )
      .then(() => {
        setLoading(false);
        closeDrawer(true);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  return (
    <Form
      size={ui_size}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      name="agent_payment_method_form"
      onFinish={submitHandler}
    >
      <Row justify={"end"}>
        <Col span={24}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="description" label="Description">
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            name="account_type"
            label="Account Type"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              options={[
                { label: "KPay", value: "kpay" },
                { label: "Wave", value: "wave" },
              ]}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="account_no"
            label="Account No"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
