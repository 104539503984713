import React, { useState, useEffect } from "react";
import {
  Descriptions,
  Typography,
  message,
  Card,
  Row,
  Col,
  Image,
  Tag,
  Divider,
} from "antd";
import moment from "moment";
import { getListFromServer } from "../../lib/apis";
import { AGENT_USER_BALL_MAUNG_DETAIL } from "../../lib/config";
import { useSelector } from "react-redux";

const { Text } = Typography;

const DescriptionItem = ({ label, value, itemStyle }) => {
  return (
    <Descriptions style={itemStyle} column={1}>
      <Descriptions.Item label={<Text className="text-success">{label}</Text>}>
        <Text>{value}</Text>
      </Descriptions.Item>
    </Descriptions>
  );
};

const formatDateTime = (dateTime, type) => {
  return type === "date"
    ? moment(dateTime).format("MMM DD YYYY")
    : moment(dateTime).format("h:mm A");
};

const renderTeamDetails = (team, isKeyTeam) => (
  <Col
    span={8}
    style={{
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <Image src={team?.logo} width={56} preview={false} />
    <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
      <div style={{ flex: 1 }}>
        <Text>{team?.name || "...."}</Text>
      </div>
      <div style={{ marginLeft: "8px" }}>
        {isKeyTeam && <Text className="text-success">{team?.keyMatch}</Text>}
      </div>
    </div>
  </Col>
);

const BallMaungDetail = ({ data }) => {
  const { access_token } = useSelector((state) => state.user);
  const [ballMaungDetail, setBallMaungDetail] = useState([]);
  const [loading, setLoading] = useState(false);

  const getDataList = async () => {
    setLoading(true);
    await getListFromServer(
      `${AGENT_USER_BALL_MAUNG_DETAIL}/${data.user_id}`,
      access_token
    )
      .then((res) => {
        setLoading(false);
        setBallMaungDetail(res.data);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  };

  useEffect(() => {
    getDataList();
  }, []);

  if (!data || !ballMaungDetail) return null;

  const {
    user_id,
    is_finish,
    account,
    is_win,
    final_point,
    bit_amount,
    bet_time,
    updated_at,
  } = data;

  return (
    <>
      <DescriptionItem label="ID" value={user_id} />
      <DescriptionItem label="Game Type" value="Maung" />
      <DescriptionItem
        label="Account"
        value={account}
      />

      <DescriptionItem label="Is Finish?" value={is_finish ? "Yes" : "No"} />
      <DescriptionItem
        label="Is Win?"
        value={is_finish ? (is_win ? "နိုင်" : "ရှုံး") : "-"}
      />
      <DescriptionItem
        label="Final Point"
        value={final_point}
        itemStyle={{ marginTop: "30px" }}
      />
      <DescriptionItem label="Bit Amount" value={bit_amount || "-"} />
      <DescriptionItem
        label="Bet Time"
        value={formatDateTime(bet_time, "time")}
      />
      <DescriptionItem
        label="Updated At"
        value={formatDateTime(updated_at, "time")}
      />

      {ballMaungDetail.map((item, index) => {
        return (
          <div key={index}>
            {item.bet_maung.map((betItem, betIndex) => {
              const {
                bet_team,
                bet_type,
                team_id,
                key,
                team_one_goal,
                team_two_goal,
                goalPlus,
                match: { team_one, team_two, match_time },
              } = betItem;

              const keyTeamName =
                team_id === team_one.id ? team_one.name_en : team_two.name_en;

              const keyOddsChange = () => {
                if (team_one.key_word || team_two.key_word) {
                  return key;
                }
              };

              return (
                <Card
                  loading={loading}
                  key={`${index}-${betIndex}`}
                  title={formatDateTime(match_time, "time")}
                  className="bg-card mt-5"
                >
                  <Row align="middle">
                    {renderTeamDetails(
                      {
                        logo: team_one.logo,
                        name: team_one.name_en,
                        keyMatch: keyOddsChange(),
                      },
                      bet_team.id === team_one.id
                    )}
                    <Col
                      span={8}
                      style={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Tag
                        className="goal-card py-1 px-2 rounded-lg"
                        style={{ fontSize: "16px" }}
                      >
                        {team_one_goal} : {team_two_goal}
                      </Tag>
                      <Tag
                        className="bg-success py-1 px-2 rounded-lg"
                        style={{ marginTop: "8px" }}
                      >
                        {goalPlus}
                      </Tag>
                    </Col>
                    {renderTeamDetails(
                      {
                        logo: team_two.logo,
                        name: team_two.name_en,
                        keyMatch: keyOddsChange(),
                      },
                      bet_team.id === team_two.id
                    )}
                  </Row>
                  <Divider style={{ borderColor: "white" }} className="my-4" />
                  <Row>
                    <Col
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                      }}
                    >
                      <Text className="text-success">Selected</Text>
                      {bet_type === "KEY" && (
                        <Text style={{ marginTop: "5px" }}>{keyTeamName}</Text>
                      )}
                      {bet_type === "GOAL_UP" && team_id === team_one.id && (
                        <Text>GOAL_UP</Text>
                      )}
                      {bet_type === "GOAL_UP" && team_id !== team_one.id && (
                        <Text>GOAL_DOWN</Text>
                      )}
                    </Col>
                  </Row>
                </Card>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default BallMaungDetail;
