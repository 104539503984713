import { Button, Card, Col, Form, Input, Row, Typography, message } from "antd";
import { AgentLogin, CmsLogin } from "../lib/apis";
import { useDispatch } from "react-redux";
import { updateUser } from "../store/user";
import { useState } from "react";
const { Text } = Typography;

export default function LoginPage() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const is_agent = window.location.href.includes("agent");

  async function submitHandler(credential) {
    setLoading(true);
    is_agent
      ? await AgentLogin(credential)
          .then(({ data: { access_token, user } }) => {
            setLoading(false);
            dispatch(
              updateUser({
                id: user.id,
                name: user.name,
                access_lvl_id: "",
                email: user.email,
                phone_no: user.phone_no,
                login_name: user.login_name,
                select_theme: user.theme,
                ui_size: user.ui_size,
                permissions: user.games
                  ? ["IS_AGENT", ...user.games]
                  : ["IS_AGENT"],
                access_token,
                first_time_promo: user.first_time_promo,
                agent_points: user.agent_points,
                agent_code: user.agent_code,
                address: user.address,
                phone: user.phone,
                profile_url: user.profile_url,
                is_api_only: user.is_api_only,
                callback_url: user.callback_url,
              })
            );
          })
          .catch((error) => {
            setLoading(false);
            message.error(error?.response?.data?.error || "Connection Error");
          })
      : await CmsLogin(credential)
          .then(({ data: { access_token, user } }) => {
            setLoading(false);
            dispatch(
              updateUser({
                id: user.id,
                name: user.name,
                access_lvl_id: user.access_lvl_id,
                email: user.email,
                phone_no: user.phone_no,
                login_name: user.login_name,
                select_theme: user.theme,
                ui_size: user.ui_size,
                permissions: user.access_permissions.permissions,
                access_token,
                first_time_promo: 0,
              })
            );
          })
          .catch((error) => {
            setLoading(false);
            message.error(error?.response?.data?.error || "Connection Error");
          });
  }

  return (
    <Row justify={"center"} align={"middle"} style={{ height: "100%" }}>
      <Col>
        <Card>
          <Row style={{ marginBottom: "24px" }} justify={"center"}>
            <Col>
              <Text strong style={{ fontSize: "2rem" }}>
                {is_agent ? "Login Ak ( Agent )" : "Login Ak Admin"}
              </Text>
            </Col>
          </Row>
          <Form
            onFinish={submitHandler}
            style={{ width: "max-content" }}
            name="login"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
          >
            <Row justify={"end"}>
              <Col span={24}>
                <Form.Item
                  name="login_name"
                  label="User Name"
                  rules={[
                    {
                      required: true,
                      message: "Please Input your User Name",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ required: true, message: "Password is Required" }]}
                >
                  <Input type="password" />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button type="primary" loading={loading} htmlType="submit">
                    Login
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}
