import { BulbFilled, BulbOutlined, LogoutOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Layout,
  Row,
  message,
  theme,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { logOff, updateUser } from "../store/user";
import { UpdateDateOnServer } from "../lib/apis";
import { CMS_THEME_RESET_API } from "../lib/config";
import { useState } from "react";
import { useNavigate } from "react-router";
export default function LayoutHeader() {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const navigage = useNavigate();

  async function UpdateTheme(value) {
    setLoading(true);
    await UpdateDateOnServer(
      CMS_THEME_RESET_API,
      user.id,
      {
        theme: value,
      },
      user.access_token
    )
      .then(() => {
        setLoading(false);
        dispatch(updateUser({ ...user, select_theme: value }));
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  const dispatch = useDispatch();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const items = [
    {
      label: (
        <Button
          type="text"
          icon={
            user.select_theme === "dark" ? <BulbFilled /> : <BulbOutlined />
          }
          onClick={() =>
            UpdateTheme(user.select_theme === "dark" ? "white" : "dark")
          }
        >
          Theme
        </Button>
      ),
      key: "theme",
    },
    {
      label: (
        <Button
          onClick={() => {
            dispatch(logOff());
            navigage("/");
          }}
          type="text"
          icon={<LogoutOutlined />}
        >
          Log Out
        </Button>
      ),
      key: "logOut",
    },
  ];

  return (
    <Layout.Header
      style={{ background: colorBgContainer, paddingRight: "16px" }}
    >
      <Row justify={"end"}>
        <Col>
          <Dropdown menu={{ items }} placement="bottomRight">
            <Button type="text">{user.name}</Button>
          </Dropdown>
        </Col>
      </Row>
    </Layout.Header>
  );
}
