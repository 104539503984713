import { createSlice } from "@reduxjs/toolkit";

const originFormat = {
  id: false,
  name: "",
  access_lvl_id: "",
  email: "",
  login_name: "",
  phone_no: "",
  permissions: [],
  access_token: "",
  select_theme: "dark",
  ui_size: "normal",
  first_time_promo: 0,
  agent_points: 0,
  agent_code: 0,
  address: "",
  phone: "",
  profile_url: "",
  is_api_only: false,
  transaction_noti: false,
  user_noti: false,
  callback_url: "",
};

function addSession(data) {
  sessionStorage.setItem("user", JSON.stringify(data));
}

function removeSession() {
  sessionStorage.removeItem("user");
}

function getSession() {
  const user = sessionStorage.getItem("user");
  if (user) {
    return JSON.parse(user);
  }
  return originFormat;
}

const initialState = getSession();

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, { payload: user }) => {
      state.id = user.id;
      state.name = user.name;
      state.access_lvl_id = user.access_lvl_id;
      state.email = user.email;
      state.phone_no = user.phone_no;
      state.permissions = user.permissions;
      state.access_token = user.access_token;
      state.login_name = user.login_name;
      state.select_theme = user.select_theme;
      state.ui_size = user.ui_size;
      state.first_time_promo = user.first_time_promo;
      state.agent_points = user.agent_points || 0;
      state.agent_code = user.agent_code || 0;
      state.address = user.address || "";
      state.phone = user.phone || "";
      state.profile_url = user.profile_url || "";
      state.is_api_only = user.is_api_only || false;
      state.callback_url = user.callback_url || "";
      state.transaction_noti = user.transaction_noti || false;
      state.user_noti = user.user_noti || false;

      addSession({
        ...user,
        email: user.email ? user.email : "",
        phone_no: user.phone_no ? user.phone_no : "",
      });
    },
    logOff: (state) => {
      removeSession();
      state.id = false;
    },
  },
});

export const { updateUser, logOff } = userSlice.actions;
export default userSlice.reducer;
