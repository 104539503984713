import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Typography,
  message,
} from "antd";
import { useSelector } from "react-redux";
import { CreateDataOnServer, GetDataListFromServer } from "../../lib/apis";
import {
  CMS_PAYMENT_METHOD_LIST_API,
  CMS_TRANSACTION_CREATE_API,
} from "../../lib/config";
import { useEffect, useState } from "react";
import { QUERY_CMS_PAYMENT_METHOD } from "../../lib/query_objects";
import { queryHandler } from "../../lib/globle_functions";

const { Text } = Typography;

export default function TransactionForm({ agent, closeDrawer }) {
  const { ui_size, access_token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);

  async function getPayment() {
    setLoading(true);
    await GetDataListFromServer(
      CMS_PAYMENT_METHOD_LIST_API,
      queryHandler(
        {
          name: "",
          account_type: "",
          account_no: "",
          is_deleted: "false",
          order_by: "created_at",
          order_type: "DESC",
        },
        QUERY_CMS_PAYMENT_METHOD,
        { page_number: 1, per_page: 1000 }
      ),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setPayments(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getPayment();
  }, []);

  async function submitHandler(value) {
    setLoading(true);
    await CreateDataOnServer(
      CMS_TRANSACTION_CREATE_API,
      { ...value, agent_id: agent.id },
      access_token
    )
      .then(() => {
        setLoading(false);
        closeDrawer(true);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  return (
    <Form
      size={ui_size}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      name="payment_method_form"
      onFinish={submitHandler}
      initialValues={{ currency: "MMK", addition: true }}
    >
      <Row justify={"end"}>
        <Col span={24}>
          <Text>Current Point: {agent?.agent_points || 0}</Text>
          <Divider />
        </Col>
        <Col span={24}>
          <Form.Item
            name="payment_method_id"
            label="Payment Method"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              options={payments.map((payment) => ({
                label: payment.name,
                value: payment.id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={20}>
          <Form.Item
            labelCol={{ span: 10 }}
            name="amount"
            label="Amount"
            rules={[{ required: true }]}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item wrapperCol={{ span: 24 }} name="currency">
            <Select
              options={[
                { label: "MMK", value: "MMK" },
                { label: "USD", value: "USD" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={20}>
          <Form.Item
            labelCol={{ span: 10 }}
            name="points"
            label="Points"
            rules={[{ required: true }]}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Row justify={"center"}>
            <Col>
              <Form.Item wrapperCol={{ span: 24 }} name="addition">
                <Switch checkedChildren="+ Add" unCheckedChildren="- Sub" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Form.Item name="note" label="Note">
            <Input.TextArea />
          </Form.Item>
        </Col>
        <Col>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
