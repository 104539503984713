import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetDataListFromServer, UpdateSingleDataOnServer } from "../lib/apis";
import { Button, Col, Drawer, Popconfirm, Row, Table, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  GAME_TWOD_THREED_AGENT_LIST_API,
  GAME_TWOD_THREED_AGENT_UPDATE_API,
} from "../lib/config";
import { QUERY_TWOD_THREED_AGENT } from "../lib/query_objects";
import { moneyFormat, queryHandler } from "../lib/globle_functions";
import TwodThreeDAgentCreateForm from "../components/game_twod_threed/twod_threed_admin_form";
import { render } from "@testing-library/react";

export default function GameTwodTheeedAdmin() {
  const { permissions, access_token, ui_size } = useSelector(
    (state) => state.user
  );
  const [list, setList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });
  const [filter, setFilter] = useState({
    agent_code: "",
    order_by: "created_at",
    order_type: "DESC",
  });

  async function getDataList(page_number, per_page) {
    setLoading(true);
    await GetDataListFromServer(
      GAME_TWOD_THREED_AGENT_LIST_API,
      queryHandler(filter, QUERY_TWOD_THREED_AGENT, pagin),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setPagin({ ...pagin, total, page_number, per_page });
        setList(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function unlockUser(id) {
    setLoading(true);
    await UpdateSingleDataOnServer(
      GAME_TWOD_THREED_AGENT_UPDATE_API,
      id,
      access_token
    )
      .then(() => {
        getDataList(pagin.page_number, pagin.per_page);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getDataList(pagin.page_number, pagin.per_page);
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: ["agent", "name"],
    },
    {
      title: "Agent Code",
      dataIndex: "agent_code",
      align: "center",
    },
    {
      title: "2D min Bit",
      dataIndex: "min_twod_amount",
      align: "center",
      render: (min_twod_amount) => moneyFormat(min_twod_amount),
    },
    {
      title: "2D max Bit",
      dataIndex: "max_twod_amount",
      align: "center",
      render: (max_twod_amount) => moneyFormat(max_twod_amount),
    },
    {
      title: "2D limit",
      dataIndex: "twod_limit",
      align: "center",
      render: (twod_limit) => moneyFormat(twod_limit),
    },
    {
      title: "3D min Bit",
      dataIndex: "min_threed_amount",
      align: "center",
      render: (min_threed_amount) => moneyFormat(min_threed_amount),
    },
    {
      title: "3D max Bit",
      dataIndex: "max_threed_amount",
      align: "center",
      render: (min_threed_amount) => moneyFormat(min_threed_amount),
    },
    {
      title: "3D limit",
      dataIndex: "threed_limit",
      align: "center",
      render: (threed_limit) => moneyFormat(threed_limit),
    },
    {
      title: "",
      render: (_, user) => (
        <Row gutter={16} justify={"end"}>
          <Col>
            <Popconfirm
              title={`Are you sure ${
                user.is_active ? "Remove 2d/3d game from" : "Add 2d/3d game to"
              } this user ?`}
              onConfirm={() => unlockUser(user.agent_id)}
              okText="Yes"
              cancelText="No"
              disabled={!permissions.includes("TWOD_THREED_DELETE")}
            >
              {user.is_active ? (
                <Button
                  size={ui_size}
                  style={{ width: "100px" }}
                  type="primary"
                  danger
                >
                  Remove
                </Button>
              ) : (
                <Button
                  size={ui_size}
                  style={{ width: "100px" }}
                  type="primary"
                >
                  Add
                </Button>
              )}
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  function closeDrawer(key) {
    if (key) {
      getDataList(pagin.page_number, pagin.per_page);
    }
    setEdit(false);
    setShowForm(false);
  }

  return (
    <>
      <Row gutter={[0, 16]} justify={"end"}>
        <Col>
          {permissions.includes("TWOD_THREED_CREATE") && (
            <Button
              size={ui_size}
              type="primary"
              onClick={() => setShowForm(true)}
              icon={<PlusOutlined />}
            >
              New Agent
            </Button>
          )}
        </Col>
        <Col span={24}>
          <Table
            size={ui_size}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={list}
            loading={loading}
            pagination={{
              total: pagin.total,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 50],
              onChange: (pageNumber, perPage) => {
                getDataList(pageNumber, perPage);
              },
            }}
          />
        </Col>
      </Row>
      <Drawer
        open={showForm}
        onClose={() => closeDrawer()}
        title={"Add 2D/3D"}
        destroyOnClose
        placement="right"
        width={"900px"}
      >
        <TwodThreeDAgentCreateForm closeDrawer={closeDrawer} />
      </Drawer>
    </>
  );
}
