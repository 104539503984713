import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CreateDataOnServer, GetDataFromServer } from "../lib/apis";
import { GAME_SHAN_AGENT_KICK_USER_API, GAME_SHAN_AGENT_TABLE_API } from "../lib/config";
import {
  Button,
  Card,
  Col,
  Input,
  message,
  Popconfirm,
  Row,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from "antd";

const { Text } = Typography;

export default function AgentShanTablesPage() {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { permissions, access_token } = useSelector((state) => state.user);
  const [filterUser, setFilterUser] = useState("");

  async function getList() {
    setLoading(true);
    await GetDataFromServer(GAME_SHAN_AGENT_TABLE_API, access_token)
      .then(({ data }) => {
        setList(data);
      })
      .catch((error) =>
        message.error(error?.response?.data?.error || "Connection Error")
      )
      .finally(() => setLoading(false));
  }

  async function kickUser(user_id, room_no) {
    setLoading(true);
    await CreateDataOnServer(
      GAME_SHAN_AGENT_KICK_USER_API,
      { user_id, room_no },
      access_token
    )
      .then(() => {
        getList();
      })
      .catch((error) =>
        message.error(error?.response?.data?.error || "Connection Error")
      )
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    getList();

    const intefval = setInterval(() => {
      getList();
    }, 10000);

    return () => clearInterval(intefval);
  }, []);

  function colorHandler(player, dealer_index) {
    switch (true) {
      case !!player && player.player_index === dealer_index:
        return "gold";
        break;
      case !!player && player.is_bot:
        return "blue";
        break;
      case !!player:
        return "green";
        break;
      default:
        return "#8583832f";
        break;
    }
  }

  return (
    <Spin tip="Loading..." spinning={loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row gutter={24}>
            <Col>
              <Input
                value={filterUser}
                onChange={(e) => setFilterUser(e.target.value)}
                placeholder="User Code Here"
              />
            </Col>
            <Col>
              <Button type="primary" onClick={() => setFilterUser("")}>
                Clear Filter
              </Button>
            </Col>
          </Row>
        </Col>
        {list
          .filter((room) =>
            filterUser === ""
              ? true
              : room.players.find(
                  (player) =>
                    player &&
                    player.user_code &&
                    player.user_code.includes(filterUser)
                )
          )
          .map((room, index) => (
            <Col xl={8} xxl={6} key={index}>
              <Card
                styles={{ body: { padding: "16px" } }}
                title={
                  <Row justify={"space-between"}>
                    <Col>
                      <Text>{`Room No : ${room.room_no}`}</Text>
                    </Col>
                    <Col>
                      <Text>{`Amount : ${room.room_amount}`}</Text>
                    </Col>
                  </Row>
                }
              >
                <Row gutter={[0, 16]}>
                  <Col span={12}>
                    <Text>Room State: {room.room_state}</Text>
                  </Col>
                  <Col span={12}>
                    <Text>Warning Count: {room.warning_count}</Text>
                  </Col>
                  <Col>
                    <Card
                      title="Players"
                      styles={{
                        body: { padding: "8px", background: "#6d2e2e49" },
                        title: { padding: "4px", color: "#28e217ff" },
                      }}
                    >
                      <Row justify={"space-between"} gutter={[8, 8]}>
                        {room.players.map((player, player_index) => (
                          <Col
                            span={12}
                            key={`room_${index}_player_${player_index}`}
                          >
                            <Tooltip
                              title={
                                player && !player.is_bot
                                  ? "Click to kick this player"
                                  : ""
                              }
                            >
                              <Popconfirm
                                title="Are you sure to kick this player?"
                                disabled={!player || player.is_bot}
                                onConfirm={() =>
                                  kickUser(player.id, room.room_no)
                                }
                              >
                                <Tag
                                  style={{
                                    width: "100%",
                                    lineHeight: "2rem",
                                    cursor: "pointer",
                                  }}
                                  color={colorHandler(
                                    player,
                                    room.dealer_index
                                  )}
                                >
                                  Chair
                                  {player
                                    ? player.player_index + 1
                                    : player_index + 1}{" "}
                                  ={" "}
                                  {player
                                    ? player.user_code
                                      ? player.user_code
                                      : "BOT"
                                    : "---"}
                                </Tag>
                              </Popconfirm>
                            </Tooltip>
                          </Col>
                        ))}
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
      </Row>
    </Spin>
  );
}
