import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { message } from "antd";
import { UPLOAD_API } from "./config";
import axios from "axios";

function requestHeader(access_token) {
  return { headers: { Authorization: `Bearer ${access_token}` } };
}

async function uploadImages({
  onSuccess,
  onError,
  file,
  onProgress,
  folder_name,
  access_token,
  image,
  setImage,
}) {
  const fmData = new FormData();
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: (event) => {
      onProgress({ percent: (event.loaded / event.total) * 100 });
    },
  };

  fmData.append("file", file);
  fmData.append("folder_name", folder_name);

  if (image && image !== "") {
    fmData.append("old_file_route", image);
  }

  await axios
    .post(UPLOAD_API, fmData, config)
    .then(({ data }) => {
      setImage(data);
      onSuccess("ok");
    })
    .catch((error) => onError({ error }));
}

const paginationParams = {
  default: {
    page: 'page_number',
    per_page: 'per_page'
  },
  alternative: {
    page: 'page',
    per_page: 'perPage'
  }
};

function queryHandler(filter, queryObject, pagination, paramType='default') {
  const { page, per_page } = paginationParams[paramType];

  let query = `&${page}=${pagination.page_number}&${per_page}=${pagination.per_page}`;

  if (
    filter.order_by &&
    filter.order_type &&
    queryObject.order.includes(filter.order_by)
  ) {
    query =
      query + `&order_by=${filter.order_by}&order_type=${filter.order_type}`;
  }

  for (let i of queryObject.filter) {
    switch (true) {
      case i.type === "string" && filter[i.name] !== "":
        query = query + `&${i.name}=${filter[i.name]}`;
        break;
      case i.type === "boolean" && filter[i.name] !== "":
        query = query + `&${i.name}=${filter[i.name]}`;
        break;
      default:
        break;
    }
  }
  return query;
}

function setCookie(name, value, expiredays) {
  const days = new Date();
  days.setTime(days.getTime() + expiredays * 24 * 60 * 60 * 1000);
  let expires = `expires = ${days.toUTCString()}`;
  document.cookie = `${name} = ${value}; ${expires}; path=/`;
}

function getCookie(name) {
  let nameEQ = `${name}=`;
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function beforeUpload(file, size) {
  const isJpgOrPng =
    file.type === "image/jpeg" ||
    file.type === "image/png" ||
    file.type === "image/jpg";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
    return false;
  }

  const isLimit = file.size / 1024 / 1024 < size;
  if (!isLimit) {
    message.error(`Image must smaller than ${size} MB!`);
    return false;
  }

  return true;
}

function uploadButton(loading) {
  return (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );
}

function moneyFormat(amount) {
  return amount.toLocaleString("en-US")
}

export {
  requestHeader,
  uploadImages,
  queryHandler,
  setCookie,
  getCookie,
  beforeUpload,
  uploadButton,
  moneyFormat,
};
