import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetDataListFromServer, UpdateSingleDataOnServer } from "../lib/apis";
import { Button, Col, Drawer, Row, Table, Tag, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  GAME_TWOD_THREED_AGENT_UPDATE_API,
  GAME_TWOD_THREED_TWOD_HOLIDAY_LIST_API,
} from "../lib/config";
import {
  QUERY_TWOD_THREED_HOLIDAY,
} from "../lib/query_objects";
import { queryHandler } from "../lib/globle_functions";
import TwodThreedHolidayForm from "../components/game_twod_threed/hoilday_form";
import dayjs from "dayjs";

export default function GameTwodHolidayPage() {
  const { permissions, access_token, ui_size } = useSelector(
    (state) => state.user
  );
  const [list, setList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });
  const [filter, setFilter] = useState({
    order_by: "holiday",
    order_type: "DESC",
  });

  async function getDataList(page_number, per_page) {
    setLoading(true);
    await GetDataListFromServer(
      GAME_TWOD_THREED_TWOD_HOLIDAY_LIST_API,
      queryHandler(filter, QUERY_TWOD_THREED_HOLIDAY, pagin),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setPagin({ ...pagin, total, page_number, per_page });
        setList(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function unlockUser(id) {
    setLoading(true);
    await UpdateSingleDataOnServer(
      GAME_TWOD_THREED_AGENT_UPDATE_API,
      id,
      access_token
    )
      .then(() => {
        getDataList(pagin.page_number, pagin.per_page);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getDataList(pagin.page_number, pagin.per_page);
  }, []);

  const columns = [
    {
      title: "Holiday Date",
      dataIndex: 'holiday',
      align:'center',
      render:(holiday)=>dayjs(holiday).format("YYYY-MM-DD")
    },
    {
      title:'Is Over',
      dataIndex:'holiday',
      align:'center',
      render:(holiday)=><Tag>{dayjs(holiday) < dayjs() ? "Over" : "Still Under Way"}</Tag>
    },
    {
      title: "",
      render: (_, user) => (
        <Row gutter={16} justify={"end"}>
          <Col>
                <Button
                  size={ui_size}
                  style={{ width: "100px" }}
                  disabled={!permissions.includes("TWOD_THREED_CREATE")}
                  type="primary"
                  onClick={()=>{
                    setEdit(user)
                    setShowForm(true)
                  }}
                >Edit
                </Button>
          </Col>
        </Row>
      ),
    },
  ];

  function closeDrawer(key) {
    if (key) {
      getDataList(pagin.page_number, pagin.per_page);
    }
    setEdit(false);
    setShowForm(false);
  }

  return (
    <>
      <Row gutter={[0, 16]} justify={"end"}>
        <Col>
          {permissions.includes("TWOD_THREED_CREATE") && (
            <Button
              size={ui_size}
              type="primary"
              onClick={() => setShowForm(true)}
              icon={<PlusOutlined />}
            >
              New Holiday
            </Button>
          )}
        </Col>
        <Col span={24}>
          <Table
            size={ui_size}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={list}
            loading={loading}
            pagination={{
              total: pagin.total,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 50],
              onChange: (pageNumber, perPage) => {
                getDataList(pageNumber, perPage);
              },
            }}
          />
        </Col>
      </Row>
      <Drawer
        open={showForm}
        onClose={() => closeDrawer()}
        title={edit ? "Update Holiday" : "Create Holiday"}
        destroyOnClose
        placement="right"
        width={"500px"}
      >
        <TwodThreedHolidayForm edit={edit} closeDrawer={closeDrawer} />
      </Drawer>
    </>
  );
}
