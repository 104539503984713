import {
  Button,
  Col,
  Drawer,
  Menu,
  message,
  Modal,
  Popconfirm,
  Row,
  Typography,
} from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { DeleteDataOnServer, UpdateSingleDataOnServer } from "../../lib/apis";
import { CMS_AGENT_DELETE_API, CMS_AGENT_UNLOCK_API } from "../../lib/config";
import AgentCredentialReset from "./password";
import CmsAgentForm from "./form";

const { Text } = Typography;

export default function AgentDetail({ data, closeDrawer }) {
  const { permissions, access_token, ui_size } = useSelector(
    (state) => state.user
  );

  const [showForm, setShowForm] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  async function closeModel(value) {
    if (value) {
      closeDrawer(true);
    }
    setEdit(false);
    setShowModel(false);
  }

  async function unlockUser(id) {
    setLoading(true);
    await UpdateSingleDataOnServer(CMS_AGENT_UNLOCK_API, id, access_token)
      .then(() => {
        closeDrawer(true);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function cmsUserDelete(id) {
    setLoading(true);
    await DeleteDataOnServer(CMS_AGENT_DELETE_API, id, access_token)
      .then(() => {
        closeDrawer(true);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  return (
    <>
      <Row>
        <Col span={4}>
          <Text>Agent Code</Text>
        </Col>
        <Col span={20}>
          <Text>{data.agent_code}</Text>
        </Col>
        <Col span={4}>
          <Text>Name</Text>
        </Col>
        <Col span={20}>
          <Text>{data.name}</Text>
        </Col>
        <Col span={4}>
          <Text>login Name</Text>
        </Col>
        <Col span={20}>
          <Text>{data.login_name}</Text>
        </Col>
        <Col span={4}>
          <Text>Email</Text>
        </Col>
        <Col span={20}>
          <Text>{data.email || "---"}</Text>
        </Col>
        <Col span={4}>
          <Text>Phone No</Text>
        </Col>
        <Col span={20}>
          <Text>{data.phone || "---"}</Text>
        </Col>
        <Col span={4}>
          <Text>agent Points</Text>
        </Col>
        <Col span={20}>
          <Text>{data.agent_points || 0}</Text>
        </Col>
        <Col span={24}>
          <Row gutter={16} style={{ marginTop: "24px" }}>
            <Col>
              <Popconfirm
                title="Are You Sure Unlock This Agent ?"
                onConfirm={() => unlockUser(data.id)}
                okText="Yes"
                cancelText="No"
                disabled={
                  !permissions.includes("CMS_AGENT_UPDATE") || data.is_deleted
                }
              >
                <Button
                  disabled={
                    !data.is_locked ||
                    !permissions.includes("CMS_AGENT_UPDATE") ||
                    data.is_deleted
                  }
                  size={ui_size}
                  onClick={() => unlockUser(data.id)}
                  type="dashed"
                >
                  Unlock
                </Button>
              </Popconfirm>
            </Col>
            <Col>
              <Button
                disabled={
                  !permissions.includes("CMS_AGENT_CREDENTIAL_RESET") ||
                  data.is_deleted
                }
                size={ui_size}
                onClick={() => {
                  setEdit(data);
                  setShowModel(true);
                }}
                type="primary"
              >
                Reset Password
              </Button>
            </Col>
            <Col>
              <Button
                size={ui_size}
                disabled={
                  !permissions.includes("CMS_AGENT_UPDATE") || data.is_deleted
                }
                type="primary"
                onClick={() => {
                  setEdit(data);
                  setShowForm(true);
                }}
              >
                Edit
              </Button>
            </Col>
            <Col>
              <Popconfirm
                title="Are You Sure Delete This User ?"
                description="Delete can't recover"
                onConfirm={() => cmsUserDelete(data.id)}
                okText="Yes"
                cancelText="No"
                disabled={
                  !permissions.includes("CMS_AGENT_DELETE") || data.is_deleted
                }
              >
                <Button
                  disabled={
                    !permissions.includes("CMS_AGENT_DELETE") || data.is_deleted
                  }
                  size={ui_size}
                  type="primary"
                  danger
                >
                  Delete
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </Col>
      </Row>
      <Drawer
        open={showForm}
        onClose={() => setShowForm(false)}
        title={"Edit Agent"}
        destroyOnClose
        placement="right"
        width={"800px"}
      >
        <CmsAgentForm edit={edit} closeDrawer={closeDrawer} />
      </Drawer>
      <Modal
        open={showModel}
        onCancel={() => closeModel()}
        destroyOnClose
        footer={null}
        title="Credential Reset"
      >
        <AgentCredentialReset edit={edit} closeModel={closeModel} />
      </Modal>
    </>
  );
}
