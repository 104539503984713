import { Col, Row, Typography } from "antd";
import { useSelector } from "react-redux";
const { Text } = Typography;

export default function DashboardPage() {
  const { agent_code, permissions } = useSelector((state) => state.user);


  return (
    <Row>
      {permissions.includes('IS_AGENT') ? 
      <Col>
        <Text>Public Url</Text> ={" "}
        <Text copyable>{`${process.env.REACT_APP_WEB_URL}?agent_code=${agent_code}`}</Text>
      </Col> : <Col></Col>}
    </Row>
  );
}
