import { Col, Menu, Row } from "antd";
import UserDetailTab from "./detailPage";
import { useState } from "react";
import UserTransactionList from "./transactionList";
import TwodUserLogsDetail from "../game_twod_threed/twodUserLogs";
import ThreedUserLogs from "../game_twod_threed/threedUserLogs";
import PPUserDetail from "../game_pp/pp_user_detail";
import ShanUserLogs from "../game_shan/shan_user_detail";
import BallBody from "../game_ball/ball_body";
import BallMaung from "../game_ball/ball_maung";
import PPLCDetail from "../game_pp/pp_lc_detail";

export default function UserDetail({ detail, closeDrawer }) {
  const [key, setKey] = useState("detail");
  const wallet = detail;
  const items = [
    {
      label: "Detail",
      key: "detail",
    },
    {
      label: "Transactions",
      key: "transaction",
    },
    {
      label: "TwoD",
      key: "twod",
    },
    {
      label: "ThreeD",
      key: "threed",
    },
    {
      label: "PP SLOT",
      key: "pp_sl",
    },
    {
      label: "PP LC",
      key: "pp_lc",
    },
    {
      label: "Shan",
      key: "shan",
    },
    {
      label: "Ball Body",
      key: "ball_body",
    },
    {
      label: "Ball Maung",
      key: "ball_maung",
    },
  ];

  function bodyHandler() {
    switch (true) {
      case key === "transaction":
        return <UserTransactionList data={detail} closeDrawer={closeDrawer} />;
        break;
      case key === "twod":
        return <TwodUserLogsDetail data={detail} closeDrawer={closeDrawer} />;
        break;
      case key === "threed":
        return <ThreedUserLogs data={detail} closeDrawer={closeDrawer} />;
        break;
      case key === "pp_sl":
        return <PPUserDetail data={detail} closeDrawer={closeDrawer} />;
        break;
      case key === "pp_lc":
        return <PPLCDetail data={detail} closeDrawer={closeDrawer} />;
        break;
      case key === "shan":
        return <ShanUserLogs data={detail} closeDrawer={closeDrawer} />;
        break;
      case key === "ball_body":
        return <BallBody data={detail} closeDrawer={closeDrawer} />;
        break;
      case key === "ball_maung":
        return <BallMaung data={detail} closeDrawer={closeDrawer} />;
        break;
      default:
        return (
          <UserDetailTab
            data={detail}
            userId={detail?.wallect?.user_id}
            closeDrawer={closeDrawer}
          />
        );
        break;
    }
  }

  return (
    <Row>
      <Col span={24}>
        <Menu
          onClick={(e) => setKey(e.key)}
          selectedKeys={[key]}
          mode="horizontal"
          items={items}
        />
      </Col>
      <Col span={24} style={{ padding: "12px", overflowX: "hidden" }}>
        {bodyHandler()}
      </Col>
    </Row>
  );
}
