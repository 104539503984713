import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetDataListFromServer, UpdateSingleDataOnServer } from "../lib/apis";
import { Button, Col, Drawer, Popconfirm, Row, Table, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  GAME_BALL_TEAM_DELETE_API,
  GAME_BALL_TEAM_LIST_API,
} from "../lib/config";
import {  QUERY_BALL_TEAM } from "../lib/query_objects";
import { queryHandler } from "../lib/globle_functions";
import BallTeamCreateForm from "../components/game_ball/ball_team_form";

export default function GameBallTeamPage() {
  const { permissions, access_token, ui_size } = useSelector(
    (state) => state.user
  );
  const [list, setList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });
  const [filter, setFilter] = useState({
    is_deleted: "false",
    order_by: "created_at",
    order_type: "DESC",
  });

  async function getDataList(page_number, per_page) {
    setLoading(true);
    await GetDataListFromServer(
      GAME_BALL_TEAM_LIST_API,
      queryHandler(filter, QUERY_BALL_TEAM, {page_number,per_page}),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setPagin({ ...pagin, total, page_number, per_page });
        setList(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function unlockUser(id) {
    setLoading(true);
    await UpdateSingleDataOnServer(GAME_BALL_TEAM_DELETE_API, id, access_token)
      .then(() => {
        getDataList(pagin.page_number, pagin.per_page);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getDataList(pagin.page_number, pagin.per_page);
  }, []);

  const columns = [
    {
      title: "Logo",
      dataIndex: "logo",
      render: (logo) => (
        <img src={logo} style={{ height: "50px", width: "50px" }} alt="logo" />
      ),
    },
    {
      title: "Name En",
      dataIndex: "name_en",
    },
    {
      title: "Name mm",
      dataIndex: "name_mm",
      align: "center",
    },
    {
      title: "",
      render: (_, user) => (
        <Row gutter={16} justify={"end"}>
          <Col>
            <Button
              size={ui_size}
              style={{ width: "100px" }}
              type="primary"
              disabled={!permissions.includes("BALL_CREATE")}
              onClick={() => {
                setEdit(user);
                setShowForm(true);
              }}
            >
              Edit
            </Button>
          </Col>
          <Col>
            <Popconfirm
              title={`Are you sure Delet this team ?`}
              onConfirm={() => unlockUser(user.id)}
              okText="Yes"
              cancelText="No"
              disabled={!permissions.includes("BALL_DELETE")}
            >
              <Button
                size={ui_size}
                style={{ width: "100px" }}
                type="primary"
                danger
              >
                Delete
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  function closeDrawer(key) {
    if (key) {
      getDataList(pagin.page_number, pagin.per_page);
    }
    setEdit(false);
    setShowForm(false);
  }

  return (
    <>
      <Row gutter={[0, 16]} justify={"end"}>
        <Col>
          {permissions.includes("BALL_CREATE") && (
            <Button
              size={ui_size}
              type="primary"
              onClick={() => setShowForm(true)}
              icon={<PlusOutlined />}
            >
              New Team
            </Button>
          )}
        </Col>
        <Col span={24}>
          <Table
            size={ui_size}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={list}
            loading={loading}
            pagination={{
              total: pagin.total,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 50],
              onChange: (pageNumber, perPage) => {
                getDataList(pageNumber, perPage);
              },
            }}
          />
        </Col>
      </Row>
      <Drawer
        open={showForm}
        onClose={() => closeDrawer()}
        title={edit ? "Edit Team" : "Add Team"}
        destroyOnClose
        placement="right"
        width={"900px"}
      >
        <BallTeamCreateForm edit={edit} closeDrawer={closeDrawer} />
      </Drawer>
    </>
  );
}
