import { Button, Col, Form, Input, Row, message } from "antd";
import {
  CMS_USER_LOGIN_NAME_CHECK,
  CMS_USER_PASSWORD_RESET,
} from "../../lib/config";
import { CmsLoginNameCheck, UpdateDateOnServer } from "../../lib/apis";
import { useSelector } from "react-redux";
import { useState } from "react";

export default function CredentialReset({ edit, closeModel }) {
  const { access_token, ui_size } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  async function submitHandler(value) {
    setLoading(true);
    await UpdateDateOnServer(
      CMS_USER_PASSWORD_RESET,
      edit.id,
      value,
      access_token
    )
      .then(() => {
        setLoading(false);
        closeModel(true);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  return (
    <Form
      size={ui_size}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={submitHandler}
      name="credential_reset"
      initialValues={{ login_name: edit.login_name, Password: "" }}
    >
      <Form.Item
        name="login_name"
        label="Login Name"
        validateDebounce={1000}
        rules={[
          {
            required: true,
            message: "Please Input Login Name",
          },
          {
            async validator(_, value) {
              await CmsLoginNameCheck(
                CMS_USER_LOGIN_NAME_CHECK,
                value,
                access_token
              )
                .then(({ data: { avilable } }) => {
                  if (avilable) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("This User is Already Exist");
                  }
                })
                .catch((error) => {
                  return Promise.reject("This User is Already Exist");
                });
            },
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: "Please Input Password",
          },
        ]}
      >
        <Input type="password" />
      </Form.Item>
      <Row gutter={[16, 16]} justify={"end"}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              closeModel();
            }}
          >
            Cancel
          </Button>
        </Col>
        <Col>
          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
