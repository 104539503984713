import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  TimePicker,
  Typography,
  message,
} from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CreateDataOnServer, UpdateDateOnServer } from '../../lib/apis';
import {
  GAME_BALL_MATCH_CREATE_API,
  GAME_BALL_MATCH_UPDATE_API,
} from '../../lib/config';
import dayjs from 'dayjs';

const { Text } = Typography;

export default function BallMatchCreateForm({
  edit,
  closeDrawer,
  carry_leagues,
  carry_teams,
}) {
  const { ui_size, access_token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [leagues, setLeagues] = useState(carry_leagues);
  const [teams, setTeams] = useState(carry_teams);
  const [form] = Form.useForm();

  async function submitHandler(value) {
    if (edit) {
      setLoading(true);
      const formatDate = dayjs(value.match_date).format('YYYY-MM-DD');
      const payload = { ...value, match_date: formatDate };

      await UpdateDateOnServer(
        GAME_BALL_MATCH_UPDATE_API,
        edit.id,
        payload,
        access_token
      )
        .then(() => {
          setLoading(false);
          closeDrawer(true);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.error || 'Connection Error');
        });
    } else {
      setLoading(true);
      const formatDate = dayjs(value.match_date).format('YYYY-MM-DD');

      const payload = { ...value, match_date: formatDate };
      await CreateDataOnServer(
        GAME_BALL_MATCH_CREATE_API,
        payload,
        access_token
      )
        .then(() => {
          setLoading(false);
          closeDrawer(true);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.error || 'Connection Error');
        });
    }
  }

  return (
    <Form
      variant="filled"
      size={ui_size}
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      name="ball_match_form"
      onFinish={submitHandler}
      initialValues={
        edit
          ? {
              ...edit,
              match_date: dayjs(edit.match_date),
              match_time: dayjs(edit.match_time),
              display_date: dayjs(edit.display_date),
            }
          : { is_big_match: false }
      }
    >
      <Row justify={'end'}>
        <Col span={12}>
          <Form.Item
            name="league_id"
            label="League"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="label"
              options={leagues.map((value) => ({
                value: value.id,
                label: `${value.name_en} / ${value.name_mm}`,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="team_one_id"
            label="Team One"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="label"
              options={teams.map((team) => ({
                value: team.id,
                label: `${team.name_en} / ${team.name_mm}`,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="team_two_id"
            label="Team Two"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="label"
              options={teams.map((team) => ({
                value: team.id,
                label: `${team.name_en} / ${team.name_mm}`,
              }))}
            />
          </Form.Item>
          <Form.Item name="is_big_match" label="ပွဲကြီး/သေး">
            <Select
              options={[
                { value: true, label: 'ပွဲကြီး' },
                { value: false, label: 'ပွဲသေး' },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="match_date"
            label="Match Date"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="match_time"
            label="Match Time"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TimePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="display_date"
            label="Display Date"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Text>ပြားရှုံး/ပြားနိုင်</Text>
          <Divider />
          <Row justify={'space-between'}>
            <Col span={8}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="key_team"
                label="Team"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  options={teams.map((team) => ({
                    value: team.id,
                    label: `${team.name_en} / ${team.name_mm}`,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="key_one"
                label="goal No"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: new RegExp(/^[0-9=/]/),
                    message: "Only numbers or '=' ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="key_two"
                label="Type"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  options={[
                    { value: '=', label: '=' },
                    { value: '+', label: '+' },
                    { value: '-', label: '-' },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="key_three"
                label="win/lost %"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber style={{ width: '100%' }} max={100} min={0} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Text>ဂိုးပေါ် / အောက် </Text>
          <Divider />
          <Row justify={'space-between'}>
            <Col span={8}></Col>
            <Col span={4}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="goal_plus_one"
                label="goal No"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: new RegExp(/^[0-9=/]/),
                    message: "Only numbers or '=' ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="goal_plus_two"
                label="Type"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  options={[
                    { value: '=', label: '=' },
                    { value: '+', label: '+' },
                    { value: '-', label: '-' },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="goal_plus_three"
                label="win/lost %"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber style={{ width: '100%' }} max={100} min={0} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
