import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  GetDataListFromServer,
  UpdateDateOnServer,
} from "../lib/apis";
import { Button, Col, Drawer, Popconfirm, Row, Table, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  GAME_SHAN_AGENT_LIST_API,
  GAME_SHAN_AGENT_UPDATE_API,
} from "../lib/config";
import ShanAgentCreateForm from "../components/game_shan/shan_admin_form";

export default function GameShanAdmin() {
  const { permissions, access_token, ui_size } = useSelector(
    (state) => state.user
  );
  const [list, setList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });

  async function getDataList(page_number, per_page) {
    setLoading(true);
    await GetDataListFromServer(
      GAME_SHAN_AGENT_LIST_API,
      `page_number=${page_number}&per_page=${per_page}`,
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setPagin({ ...pagin, total, page_number, per_page });
        setList(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function unlockUser(id, agent_id, is_active) {
    setLoading(true);
    await UpdateDateOnServer(
      GAME_SHAN_AGENT_UPDATE_API,
      agent_id,
      {
        id,
        is_active: !is_active,
      },
      access_token
    )
      .then(() => {
        getDataList(pagin.page_number, pagin.per_page);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getDataList(pagin.page_number, pagin.per_page);
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: ["agent", "name"],
    },
    {
      title: "Agent Code",
      dataIndex: "agent_code",
      align: "center",
    },
    {
      title: "",
      render: (_, user) => (
        <Row gutter={16} justify={"end"}>
          <Col>
            <Popconfirm
              title={`Are you sure ${
                user.is_active
                  ? "Remove Shan Komee game from"
                  : "Add Shan Komee game to"
              } this user ?`}
              onConfirm={() =>
                unlockUser(user.id, user.agent_id, user.is_active)
              }
              okText="Yes"
              cancelText="No"
              disabled={!permissions.includes("SHAN_DELETE")}
            >
              {user.is_active ? (
                <Button
                  size={ui_size}
                  style={{ width: "100px" }}
                  type="primary"
                  danger
                >
                  Remove
                </Button>
              ) : (
                <Button
                  size={ui_size}
                  style={{ width: "100px" }}
                  type="primary"
                >
                  Add
                </Button>
              )}
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  function closeDrawer(key) {
    if (key) {
      getDataList(pagin.page_number, pagin.per_page);
    }
    setShowForm(false);
  }

  return (
    <>
      <Row gutter={[0, 16]} justify={"end"}>
        <Col>
          {permissions.includes("SHAN_CREATE") && (
            <Button
              size={ui_size}
              type="primary"
              onClick={() => setShowForm(true)}
              icon={<PlusOutlined />}
            >
              New Agent
            </Button>
          )}
        </Col>
        <Col span={24}>
          <Table
            size={ui_size}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={list}
            loading={loading}
            pagination={{
              total: pagin.total,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 50],
              onChange: (pageNumber, perPage) => {
                getDataList(pageNumber, perPage);
              },
            }}
          />
        </Col>
      </Row>
      <Drawer
        open={showForm}
        onClose={() => closeDrawer()}
        title={"Add SHAN"}
        destroyOnClose
        placement="right"
        width={"900px"}
      >
        <ShanAgentCreateForm closeDrawer={closeDrawer} />
      </Drawer>
    </>
  );
}
