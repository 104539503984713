import { Layout } from "antd";
import React from "react";
import { Route, Routes } from "react-router";
import DashboardPage from "../pages/dashboard_page";
import CmsUserPage from "../pages/cms_user_page";
import AccessLevelPage from "../pages/access_level_page";
import SettingPage from "../pages/setting_page";
import CmsAgentPage from "../pages/agent_page";
import PaymentMethodPage from "../pages/payment_methods";
import TransactionPage from "../pages/transactions";
import AgentPromotionPage from "../pages/agent_promotion";
import AgentPromotionSpecialPage from "../pages/agent_promotion_special";
import AgentPaymentMehtodPage from "../pages/agent_payment_method";
import AgentSubscriptionPage from "../pages/agent_point_subscription";
import UtilityAvatar from "../pages/avatar_page";
import AgentPaymentTransaction from "../pages/agent_payment_transaction";
import UsersPage from "../pages/users";
import GameTwodTheeedAdmin from "../pages/game_twod_threed_admin_page";
import GameBallAdmin from "../pages/game_ball_admin_page";
import GameBallTeamPage from "../pages/game_ball_team_page";
import GameBallLeaguePage from "../pages/game_ball_lague_page";
import GameBallMatchPage from "../pages/game_ball_match_page";
import GameBallWinCutPage from "../pages/game_ball_win_cut";
import GameTwoDAdminPage from "../pages/game_twod_admin_page";
import GameTwodHolidayPage from "../pages/game_twod_holiday_page";
import GameThreedOngoing from "../pages/game_twod_threed_ongoing_page";
import AgentTwodPage from "../pages/agent_twod_page";
import AgentThreedPage from "../pages/agent_threed_page";
import GameShanAdmin from "../pages/game_shan_admin_page";
import AgentBallPage from "../pages/AgentBallPage";
import AgentMaungPage from "../pages/AgentMaungPage";
import AgentBallOngoing from "../pages/AgentBallOngoing";
import GameTwodOngoingList from "../pages/game_twod_ongoing_page";
import AgentShanTablesPage from "../pages/agent_shan_tables";

export default function LayoutBody() {
  return (
    <Layout.Content style={{ height: "100vh", overflow: "hidden" }}>
      <div
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          height: "calc(100vh - 70px)",
          padding: "16px",
        }}
      >
        <Routes>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/cms/user" element={<CmsUserPage />} />
          <Route path="/cms/access_level" element={<AccessLevelPage />} />
          <Route path="/cms/agent" element={<CmsAgentPage />} />
          <Route path="/settings" element={<SettingPage />} />
          <Route path="/cms/payment_method" element={<PaymentMethodPage />} />
          <Route path="/cms/transaction" element={<TransactionPage />} />
          <Route path="/cms/avatar" element={<UtilityAvatar />} />
          <Route
            path="/cms/games/twod_threed"
            element={<GameTwodTheeedAdmin />}
          />
          <Route path="/cms/games/twod_admin" element={<GameTwoDAdminPage />} />
          <Route
            path="/cms/games/twod_holiday"
            element={<GameTwodHolidayPage />}
          />
          <Route
            path="/cms/games/twod_ongoing"
            element={<GameTwodOngoingList />}
          />
          <Route
            path="/cms/games/threed_ongoing"
            element={<GameThreedOngoing />}
          />
          <Route path="/cms/games/ball" element={<GameBallAdmin />} />
          <Route path="/cms/games/ball_team" element={<GameBallTeamPage />} />
          <Route
            path="/cms/games/ball_league"
            element={<GameBallLeaguePage />}
          />
          <Route path="/cms/games/ball_match" element={<GameBallMatchPage />} />
          <Route
            path="/cms/games/ball_win_cut"
            element={<GameBallWinCutPage />}
          />
          <Route path="/cms/games/shan" element={<GameShanAdmin />} />
          <Route path="/promotion" element={<AgentPromotionPage />} />
          <Route path="/transaction" element={<AgentPaymentTransaction />} />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/twod" element={<AgentTwodPage />} />
          <Route path="/threed" element={<AgentThreedPage />} />
          <Route path="/ball_ongoing_deposit" element={<AgentBallOngoing />} />
          <Route path="/goal_forecast" element={<AgentBallPage />} />
          <Route path="/maung_goal_forecast" element={<AgentMaungPage />} />
          <Route
            path="/promotion_special"
            element={<AgentPromotionSpecialPage />}
          />
          <Route path="/shan_tables" element={<AgentShanTablesPage />} />
          <Route path="/payment_method" element={<AgentPaymentMehtodPage />} />
          <Route path="/subscription" element={<AgentSubscriptionPage />} />
          <Route path="*" element={<DashboardPage />} />
        </Routes>
      </div>
    </Layout.Content>
  );
}
