import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Typography,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PERMISSION_CONST } from "../../lib/global_consts";
import { CreateDataOnServer, UpdateDateOnServer } from "../../lib/apis";
import {
  CMS_ACCESS_LEVEL_UPDATE_API,
  CMS_SCCESS_LEVEL_CREATE_API,
} from "../../lib/config";
const { Text } = Typography;

export default function AccessLevelForm({ edit, closeDrawer }) {
  const { ui_size, access_token } = useSelector((state) => state.user);
  const [permissionError, setPermissionError] = useState(false);
  const [selectPermisisons, setSelectPermissions] = useState(
    edit?.permissions ? edit.permissions : []
  );
  useEffect(() => {}, []);
  const [loading, setLoading] = useState(false);
  async function submitHandler(value) {
    if (selectPermisisons.length === 0) {
      setPermissionError(true);
      return;
    }

    if (edit) {
      setLoading(true);
      await UpdateDateOnServer(
        CMS_ACCESS_LEVEL_UPDATE_API,
        edit.id,
        { ...value, permissions: selectPermisisons },
        access_token
      )
        .then(() => {
          setLoading(false);
          closeDrawer(true);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.error || "Connection Error");
        });
    } else {
      setLoading(true);
      await CreateDataOnServer(
        CMS_SCCESS_LEVEL_CREATE_API,
        { ...value, permissions: selectPermisisons },
        access_token
      )
        .then(() => {
          setLoading(false);
          closeDrawer(true);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.error || "Connection Error");
        });
    }
  }

  function permissionSelectionHandler() {
    return (
      <Row gutter={[16, 16]}>
        {PERMISSION_CONST.map((permi, index) => (
          <Col key={`permission_${index}`} span={8}>
            <Card style={{height:'100%'}} title={permi.label} size={ui_size}>
              <Row gutter={[16, 16]}>
                {permi.keys.map((key) => (
                  <Col span={24} key={`permission_sub_${index}_${key.key}`}>
                    <Checkbox
                      size={ui_size}
                      checked={selectPermisisons.includes(key.key)}
                      onChange={({ target: { checked } }) => {
                        if (checked && permissionError) {
                          setPermissionError(false);
                        }
                        setSelectPermissions(
                          checked
                            ? [...selectPermisisons, key.key]
                            : selectPermisisons.filter(
                                (value) => value !== key.key
                              )
                        );
                      }}
                    >
                      {key.name}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    );
  }

  return (
    <Form
      variant="filled"
      size={ui_size}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 8 }}
      name="access_level_form"
      onFinish={submitHandler}
      initialValues={{ name: edit?.name || "" }}
    >
      <Row justify={"end"}>
        <Col span={24}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Divider />
        </Col>
        <Col span={24} style={{ marginBottom: "32px" }}>
          {permissionSelectionHandler()}
          {permissionError && (
            <Text type="danger">Please select atleast one permission</Text>
          )}
        </Col>
        <Col>
          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
