import {
  Button,
  Col,
  Divider,
  InputNumber,
  Row,
  Table,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { downloadExcel } from "react-export-table-to-excel";
import { useSelector } from "react-redux";
import { moneyFormat } from "../../lib/globle_functions";
import { render } from "@testing-library/react";

const { Text } = Typography;

export default function TwodHeadPage({ data, closeDrawer }) {
  const { ui_size } = useSelector((state) => state.user);
  const [record, setRecord] = useState({
    top_number: "",
    top_bit: 0,
    total_bit: 0,
    multiplyer: 80,
    pervent_amount: 0,
    bits_numbers: [],
    upper_cut_total: 0,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data) {
      const bits_numbers = data.twod
        .filter((value) => value.current > 0)
        .sort((a, b) => b.current - a.current);
      let total = 0;

      for (let i of bits_numbers) {
        total = total + i.current;
      }

      if (total > 0) {
        setRecord({
          ...record,
          top_number:
            bits_numbers[0].key < 10
              ? `0${bits_numbers[0].key}`
              : `${bits_numbers[0].key}`,
          top_bit: bits_numbers[0].current,
          total_bit: total,
          multiplyer: 80,
          pervent_amount: data.pervent_amount || 0,
          bits_numbers,
        });
      }
    }
  }, []);

  function karHandler(amount) {
    let upper_cut_total = 0;

    for (let i of record.bits_numbers) {
      if (i.current > amount) {
        upper_cut_total = upper_cut_total + (i.current - amount);
      }
    }

    setRecord({
      ...record,
      pervent_amount: amount,
      upper_cut_total: amount === 0 ? 0 : upper_cut_total,
    });
  }

  async function downloadHandler() {
    if (record.pervent_amount && record.pervent_amount !== 0) {
      downloadExcel({
        fileName: `${data?.twod_section?.target_time}`,
        sheet: data?.twod_section?.target_time || "default",
        tablePayload: {
          header: ["number", "amount"],
          body: record.bits_numbers
            .filter((value) => value.current > record.pervent_amount)
            .map((value) => [
              value.key < 10 ? `0${value.key}` : `${value.key}`,
              value.current - record.pervent_amount,
            ]),
        },
      });
    }
  }

  const columns = [
    {
      title: "number",
      dataIndex: "key",
      render: (key) => (key < 10 ? `0${key}` : `${key}`),
    },
    {
      title: "Original ထိုးငွေ",
      dataIndex: "current",
      render: (current) => moneyFormat(current),
    },
    {
      title: "ကာပီး ထိုးငွေ",
      dataIndex: "current",
      render: (current) =>
        moneyFormat(
          record.pervent_amount && current > record.pervent_amount
            ? record.pervent_amount
            : current
        ),
    },
    {
      title: "အထက်သို့ တင်‌ငွေ",
      dataIndex: "current",
      render: (current) =>
        moneyFormat(
          record.pervent_amount && current > record.pervent_amount
            ? current - record.pervent_amount
            : 0
        ),
    },
  ];

  return (
    <Row>
      <Col span={12}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Text style={{ color: "green" }}>Original</Text>
          </Col>
          <Col span={8}>
            <Text>ထိုးငွေအများဆုံး ဂဏန်း</Text>
          </Col>
          <Col span={16}>
            <Text>{record.top_number}</Text>
          </Col>
          <Col span={8}>
            <Text>ထိုး‌ငွေ</Text>
          </Col>
          <Col span={16}>
            <Text>{moneyFormat(record.top_bit)}</Text>
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <Row gutter={[12, 12]}>
          <Col span={8}>
            <Text>စုစုပေါင်းထိုးငွေ</Text>
          </Col>
          <Col span={16}>
            <Text>{moneyFormat(record.total_bit)}</Text>
          </Col>
          <Col span={8}>
            <Text>အလျှော် အဆ</Text>
          </Col>
          <Col span={16}>
            <Text>x {80}</Text>
          </Col>
          <Col span={8}>
            <Text>စုစုပေါင်း လျှော်ငွေ </Text>
          </Col>
          <Col span={16}>
            <Text>{moneyFormat(record.top_bit * 80)}</Text>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Divider />
      </Col>
      <Col span={12}>
        <Row gutter={[12, 12]}>
          <Col span={8}>
            <Text>ကာမည့် ပမာဏ</Text>
          </Col>
          <Col span={16}>
            <Text>
              <InputNumber
                min={0}
                value={record.pervent_amount}
                onChange={(e) => karHandler(e)}
              />
            </Text>
          </Col>
          <Col span={24}></Col>
          <Col span={8}>
            <Text>ကာပီး လျှော်ငွေ</Text>
          </Col>
          <Col span={16}>
            <Text>
              {moneyFormat(
                record.pervent_amount ? record.pervent_amount * 80 : 0
              )}
            </Text>
          </Col>
          <Col span={8}>
            <Text>ကာပီး ရငွေစုစုပေါင်း</Text>
          </Col>
          <Col span={16}>
            <Text>
              {moneyFormat(record.total_bit - record.upper_cut_total)}
            </Text>
          </Col>
          <Col span={8}>
            <Text>အထက်သို့ တင်ရငွေ</Text>
          </Col>
          <Col span={16}>
            <Text>{moneyFormat(record.upper_cut_total)}</Text>
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <Row align={"bottom"} justify={"end"}>
          <Col span={8}>
            <Text>စုစုပေါင်း ထိုးဂဏန်း</Text>
          </Col>
          <Col span={16}>
            <Text>
              {record?.bits_numbers.filter((value) => value.current > 0)
                .length || 0}
            </Text>
          </Col>
          <Col span={8}>
            <Text>စုစုပေါင်း ကာဂဏန်း</Text>
          </Col>
          <Col span={16}>
            <Text>
              {record?.bits_numbers.filter((value) =>
                record.pervent_amount
                  ? value.current > record.pervent_amount
                  : false
              ).length || 0}
            </Text>
          </Col>
          <Col>
            <Button
              onClick={() => downloadHandler()}
              loading={loading}
              style={{ marginLeft: "12px" }}
              size={ui_size}
              type="primary"
            >
              အတည်ပြု ( စလစ်ထုတ် )
            </Button>
          </Col>
        </Row>
      </Col>
      <Col span={24} style={{ marginTop: "24px" }}>
        <Table
          size={ui_size}
          columns={columns}
          dataSource={record.bits_numbers}
          rowKey={(e) => `prevent_${e.key}`}
        />{" "}
      </Col>
    </Row>
  );
}
