import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetDataListFromServer, UpdateDateOnServer } from "../lib/apis";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Input,
  Popconfirm,
  Row,
  Select,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import {
  AGENT_PROMOTION_LIST_API,
  AGENT_TRANSACTION_LIST_API,
  USER_TRANSACTION_UPDATE,
} from "../lib/config";
import {
  QUERY_AGENT_PROMOTION_DEPOSIT,
  QUERY_AGENT_TRANSACTION,
} from "../lib/query_objects";
import { moneyFormat, queryHandler } from "../lib/globle_functions";
import * as moment from "moment";
import { updateUser } from "../store/user";
import UserDetail from "../components/user/detail";
import dayjs from "dayjs";
import { ReloadOutlined } from "@ant-design/icons";

const { Text } = Typography;

export default function AgentPaymentTransaction() {
  const {
    permissions,
    access_token,
    ui_size,
    first_time_promo,
    transaction_noti,
    ...agent
  } = useSelector((state) => state.user);
  const disaptch = useDispatch();
  const [list, setList] = useState([]);
  const [agentPromo, setAgentPromos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });
  const [detail, setDetail] = useState(false);
  const [filter, setFilter] = useState({
    user_id: "",
    transaction_type: "",
    payment_type: "",
    transaction_id: "",
    payment_id: "",
    is_approve: "",
    is_reject: "",
    start_date: "",
    end_date: "",
    order_by: "created_at",
    get_total: "",
    order_type: "DESC",
  });
  const [note, setNote] = useState("");

  async function getAgentPromo(page_number, per_page) {
    setLoading(true);
    await GetDataListFromServer(
      AGENT_PROMOTION_LIST_API,
      queryHandler(
        {
          name: "",
          is_deleted: "false",
          order_by: "created_at",
          order_type: "DESC",
        },
        QUERY_AGENT_PROMOTION_DEPOSIT,
        {
          ...pagin,
          page_number,
          per_page,
        }
      ),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setAgentPromos(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function closeDrawer(key) {
    setDetail(false);
    getDataList(pagin.page_number, pagin.per_page);
  }

  useEffect(() => {
    if (transaction_noti) {
      getDataList(pagin.page_number, pagin.per_page);
    }
  }, [transaction_noti]);

  useEffect(() => {
    getAgentPromo(1, 100);
  }, []);

  async function getDataList(page_number, per_page, updateFilter) {
    setLoading(true);
    await GetDataListFromServer(
      AGENT_TRANSACTION_LIST_API,
      queryHandler(
        updateFilter ? updateFilter : filter,
        QUERY_AGENT_TRANSACTION,
        {
          ...pagin,
          page_number,
          per_page,
        }
      ),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        updateFilter && setFilter(updateFilter);
        setPagin({ ...pagin, total, page_number, per_page });
        setList(result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function uploadHandler(record, targetPromo, type) {
    const data = {
      point: record.point,
      promo_point:
        targetPromo && record.accept_promo
          ? (record.point / 100) * targetPromo.points_percent
          : 0,
      is_approve: type === "accept",
      is_reject: type === "reject",
      note,
      user_points: record?.user?.wallet?.point || 0,
    };

    setLoading(true);
    await UpdateDateOnServer(
      USER_TRANSACTION_UPDATE,
      record.id,
      data,
      access_token
    )
      .then(({ data: agentData }) => {
        disaptch(
          updateUser({
            ...agent,
            permissions,
            access_token,
            ui_size,
            first_time_promo,
            transaction_noti,
            agent_points: agentData.agent_points,
          })
        );
        getDataList(pagin.page_number, pagin.per_page);
        setNote("");
      })
      .catch((error) => {
        setNote("");
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getDataList(pagin.page_number, pagin.per_page);
  }, []);

  function transactionHandler(key) {
    switch (true) {
      case key < 10:
        return `0000${key}`;
        break;
      case key < 100:
        return `000${key}`;
        break;
      case key < 1000:
        return `00${key}`;
        break;
      case key < 10000:
        return `0${key}`;
        break;
      default:
        return key;
        break;
    }
  }

  const columns = [
    {
      title: "User",
      dataIndex: ["user", "name"],
    },
    {
      title: "User Code",
      dataIndex: ["user", "user_code"],
    },
    {
      title: "Transaction Type",
      dataIndex: "transaction_type",
    },
    {
      title: "Transaction key",
      dataIndex: "id",
      render: (id) => transactionHandler(id),
    },
    {
      title: "Point",
      dataIndex: ["user","wallet","point"],
    },
    {
      title: "Approved",
      dataIndex: "is_approve",
      render: (_, record) => {
        if (record.reject_date) {
          return <Tag color="red">Rejected</Tag>;
        }

        if (record.approve_date) {
          return <Tag color="success">Approved</Tag>;
        }

        return <Tag color="warning">Waiting</Tag>;
      },
    },
    {
      title: "Date",
      dataIndex: "updated_at",
      render: (updated_at) => (
        <Text>{moment(updated_at).format("DD-MM-YYYY")}</Text>
      ),
    },
    {
      title: "",
      render: (_, record) => {
        const targetPromo = record.accept_promo
          ? agentPromo.find(
              (value) =>
                value.max_deposit_amount >= record.point &&
                value.min_deposit_amount <= record.point
            )
          : false;
        return (
          <Row justify={"end"} gutter={12}>
            <Col>
              <Popconfirm
                title="Accept Transaction"
                description={
                  <Row gutter={[12, 12]}>
                    <Col>Are you sure to Accept this Transaction ?</Col>
                    <Col span={24}>
                      <Text>Note :</Text>
                      <Input.TextArea
                        value={note}
                        onChange={(e) => setNote(e)}
                      />
                    </Col>
                  </Row>
                }
                onConfirm={() => uploadHandler(record, targetPromo, "accept")}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  size={ui_size}
                  type="primary"
                  disabled={record.approve_date || record.reject_date}
                >
                  Accept
                </Button>
              </Popconfirm>
            </Col>
            <Col>
              <Popconfirm
                title="Reject Transaction"
                description={
                  <Row gutter={[12, 12]}>
                    <Col>Are you sure to Reject this Transaction ?</Col>
                    <Col span={24}>
                      <Text>Note :</Text>
                      <Input.TextArea
                        value={note}
                        onChange={(e) => setNote(e)}
                      />
                    </Col>
                  </Row>
                }
                onConfirm={() => uploadHandler(record, targetPromo, "reject")}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  size={ui_size}
                  type="primary"
                  danger
                  disabled={record.reject_date || record.approve_date}
                >
                  Reject
                </Button>
              </Popconfirm>
            </Col>
            <Col>
              <Button type="primary" onClick={() => setDetail(record.user)}>
                Detail
              </Button>
            </Col>
          </Row>
        );
      },
    },
  ];

  function expendableHandler(record) {
    const targetPromo = record.accept_promo
      ? agentPromo.find(
          (value) =>
            value.max_deposit_amount >= record.point &&
            value.min_deposit_amount <= record.point
        )
      : false;
    return (
      <Row gutter={24}>
        <Col span={12}>
          {record.transaction_recipt ? (
            <img
              style={{ width: "100%" }}
              src={record.transaction_recipt}
              alt="slip"
            />
          ) : (
            <div
              style={{
                width: "100%",
                border: "1px solid white",
                padding: "300px 0px",
                textAlign: "center",
              }}
            >
              No photo
            </div>
          )}
        </Col>
        <Col span={12}>
          <Row gutter={[12, 12]}>
            <Col span={8}>
              <Text>Amount</Text>
            </Col>
            <Col span={16}>
              <Text>: {moneyFormat(record.points || 0)}</Text>
            </Col>
            {record.transaction_type === "FIRST_LOGIN" ? (
              <>
                <Col span={8}>
                  <Text>Type</Text>
                </Col>
                <Col span={16}>
                  <Text>: Promo</Text>
                </Col>
              </>
            ) : record.transaction_type === "DEPOSIT" ? (
              <>
                <Col span={8}>
                  <Text>Payment Type</Text>
                </Col>
                <Col span={16}>
                  <Text>: {record.paymentMethod?.account_type}</Text>
                </Col>
                <Col span={8}>
                  <Text>agent Account</Text>
                </Col>
                <Col span={16}>
                  <Text>: {record.paymentMethod?.name}</Text>
                </Col>
                <Col span={8}>
                  <Text>Agent Account No</Text>
                </Col>
                <Col span={16}>
                  <Text>: {record.paymentMethod?.account_no}</Text>
                </Col>
                <Col span={8}>
                  <Text>Request Promo</Text>
                </Col>
                <Col span={16}>
                  <Tag color={record.accept_promo ? "success" : "default"}>
                    {record.accept_promo ? "Yes" : "No"}{" "}
                    {targetPromo ? `${targetPromo.points_percent} %` : ""}
                  </Tag>
                </Col>
              </>
            ) : (
              <>
                <Col span={8}>
                  <Text>Request Payment</Text>
                </Col>
                <Col span={16}>
                  <Text>: {record.payment_type}</Text>
                </Col>
                <Col span={8}>
                  <Text>Account No</Text>
                </Col>
                <Col span={16}>
                  <Text>: {record.user?.payment_account}</Text>
                </Col>
                <Col span={8}>
                  <Text>user Current Point</Text>
                </Col>
                <Col span={16}>
                  <Text>: {record.user?.wallet.point}</Text>
                </Col>
                <Col span={8}>
                  <Text>User Total Deposit</Text>
                </Col>
                <Col span={16}>
                  <Text>: {record.user?.wallet.total_deposit}</Text>
                </Col>
                <Col span={8}>
                  <Text>User Total Withdraw</Text>
                </Col>
                <Col span={16}>
                  <Text>: {record.user?.wallet.total_withdraw}</Text>
                </Col>
                <Col span={8}>
                  <Text>Withdraw Band Point By Promotion</Text>
                </Col>
                <Col span={16}>
                  <Text>: {record.user?.wallet.withdraw_band_point}</Text>
                </Col>
              </>
            )}
            <Col span={8}>
              <Text>TransactionId</Text>
            </Col>
            <Col span={16}>
              <Text copyable>{transactionHandler(record.id)}</Text>
            </Col>
            <Col span={8}>
              <Text>Approve Date</Text>
            </Col>
            <Col span={16}>
              <Text>{record.approve_date}</Text>
            </Col>
            <Col span={8}>
              <Text>Reject Date</Text>
            </Col>
            <Col span={16}>
              <Text>{record.reject_date}</Text>
            </Col>
            <Col span={8}>
              <Text>Note</Text>
            </Col>
            <Col span={16}>
              <Text>{record.note}</Text>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  function clearTransaction() {
    getDataList(pagin.page_number, pagin.per_page, {
      user_id: "",
      transaction_type: "",
      payment_type: "",
      payment_id: "",
      is_approve: "",
      is_reject: "",
      start_date: "",
      end_date: "",
      order_by: "created_at",
      get_total: "true",
      order_type: "DESC",
    });
  }

  return (
    <>
      <Row
        gutter={[0, 16]}
        justify={"space-between"}
        style={{ minHeight: "85vh", overflowX: "hidden" }}
      >
        <Col span={24}>
          <Row style={{ marginBottom: "12px" }} gutter={[12, 12]}>
            <Col span={5}>
              <Row justify={"space-around"}>
                <Col span={12}>
                  <Text>Transaction Type</Text>
                </Col>
                <Col span={12}>
                  <Select
                    style={{ width: "100%" }}
                    options={[
                      { label: "DEPOSIT", value: "DEPOSIT" },
                      { label: "WITHDRAW", value: "WITHDRAW" },
                      { label: "FIRST_LOGIN", value: "FIRST_LOGIN" },
                    ]}
                    value={filter.transaction_type}
                    onChange={(e) =>
                      setFilter({ ...filter, transaction_type: e })
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col span={5}>
              <Row>
                <Col span={12}>
                  <Text>Transaction Id</Text>
                </Col>
                <Col span={12}>
                  <Input
                    onChange={(e) =>
                      setFilter({ ...filter, transaction_id: e.target.value })
                    }
                    value={filter.transaction_id}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row>
                <Col span={6}>
                  <Text>Date</Text>
                </Col>
                <Col span={18}>
                  <DatePicker.RangePicker
                    style={{ with: "100%" }}
                    format={"YYYY-MM-DD"}
                    value={[
                      filter.start_date !== ""
                        ? dayjs(filter.start_date, "YYYY-MM-DD")
                        : null,
                      filter.end_date !== ""
                        ? dayjs(filter.end_date, "YYYY-MM-DD")
                        : null,
                    ]}
                    onChange={(e) =>
                      setFilter({
                        ...filter,
                        start_date: e[0],
                        end_date: e[1],
                      })
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row gutter={12} justify={"end"}>
                <Col>
                  <Button type="primary" onClick={() => clearTransaction()}>
                    Clear
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    onClick={() => {
                      getDataList(pagin.page_number, pagin.per_page);
                    }}
                  >
                    Search
                  </Button>
                </Col>
                <Col>
                  <Button
                    size={ui_size}
                    onClick={() =>
                      getDataList(pagin.page_number, pagin.per_page)
                    }
                    type="dashed"
                    icon={<ReloadOutlined />}
                  >
                    Reload
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Table
            size={ui_size}
            columns={columns}
            rowKey={(record) => record.id}
            expandable={{
              expandedRowRender: (record) => expendableHandler(record),
            }}
            pagination={{
              total: pagin.total,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 50],
              onChange: (pageNumber, perPage) => {
                getDataList(pageNumber, perPage);
              },
            }}
            dataSource={list}
            loading={loading}
          />
        </Col>
      </Row>
      <Drawer
        title="User Detail"
        onClose={() => closeDrawer(false)}
        styles={{
          body: { margin: 0, padding: "0" },
          header: { borderBottom: "0px" },
        }}
        placement="right"
        width={"90vw"}
        open={!!detail}
        key={"user_detail_drawer"}
        destroyOnClose={true}
      >
        <UserDetail detail={detail} closeDrawer={closeDrawer} />
      </Drawer>
    </>
  );
}
