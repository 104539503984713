import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Form, Input, Button, message } from "antd";
import { CreateDataOnServer } from "../../lib/apis";
import { GAME_BALL_IMPORT_CREATE_MATCH_API } from "../../lib/config";

const { TextArea } = Input;

const AddListForm = ({closeDrawer}) => {
  const { access_token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (val) => {
    setLoading(true);

    const data = JSON.parse(val.content);

    const matches = data?.matches;

    console.log("matches data:", matches)

    const dataFormat = matches.map((match) => ({
      refId: match.matchId,
      league: {
        name_en: match.league.name,
        name_mm: match.league.name,
      },
      teamOne: {
        name_en: match.home.engName,
        name_mm: match.home.name,
      },
      teamTwo: {
        name_en: match.away.engName,
        name_mm: match.away.name,
      },
      homeUpper: match.homeUpper,
      goalTotal: match.goalTotal,
      goalTotalPrice: match.goalTotalPrice,
      startTime: match.startTime,
      odds: match.odds,
      price: match.price,
      highTax: match.highTax,
    }));

    await CreateDataOnServer(
      GAME_BALL_IMPORT_CREATE_MATCH_API,
      dataFormat,
      access_token
    )
      .then((res) => {
        setLoading(false);
        closeDrawer(true);
        message.success("Successfull created!");
        console.log("Create data:", res.data);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  };
  return (
    <Form onFinish={handleSubmit}>
      <Form.Item name="content">
        <TextArea
          rows={4}
          placeholder="Enter"
          style={{ height: "500px", overflowY: "auto", resize: "none" }}
        />
      </Form.Item>

      <Form.Item>
        <Button loading={loading} type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddListForm;
