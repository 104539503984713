import {
  Button,
  Col,
  Form,
  InputNumber,
  Row,
  Select,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  CreateDataOnServer,
  GetDataListFromServer,
} from "../../lib/apis";
import {
  GAME_AGENT_GET_LIST,
  GAME_TWOD_THREED_AGENT_CREATE_API,
} from "../../lib/config";
import {
  queryHandler,
} from "../../lib/globle_functions";
import { QUERY_CMS_AGENTS } from "../../lib/query_objects";

export default function TwodThreeDAgentCreateForm({ closeDrawer }) {
  const { ui_size, access_token } = useSelector((state) => state.user);
  const [agents, setAgent] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getAgents() {
    setLoading(true);
    await GetDataListFromServer(
      GAME_AGENT_GET_LIST,
      queryHandler(
        {
          name: "",
          agent_code: "",
          is_locked: "",
          is_blocked: "",
          is_deleted: "false",
          is_api_only: "false",
          order_by: "created_at",
          order_type: "DESC",
        },
        QUERY_CMS_AGENTS,
        { page_number: 1, per_page: 1000 }
      ),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setAgent(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getAgents();
  }, []);

  async function submitHandler(value) {
    await CreateDataOnServer(
      GAME_TWOD_THREED_AGENT_CREATE_API,
      value,
      access_token
    )
      .then(() => {
        setLoading(false);
        closeDrawer(true);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  return (
    <Form
      variant="filled"
      size={ui_size}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      name="cms_agent_form"
      onFinish={submitHandler}
    >
      <Row justify={"end"}>
        <Col span={24}>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            name="agent_id"
            label="Agent"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              showSearch
              options={agents.map((agent) => ({
                value: agent.id,
                label: agent.name,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="min_twod_amount"
            label="2D min bit amount"
            rules={[{ required: true }]}
          >
            <InputNumber min={100} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="max_twod_amount"
            label="2D max bit amount"
            rules={[{ required: true }]}
          >
            <InputNumber min={100} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="twod_limit"
            label="2D Limit"
            rules={[{ required: true }]}
          >
            <InputNumber min={100} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="min_threed_amount"
            label="3D min bit amount"
            rules={[{ required: true }]}
          >
            <InputNumber min={100} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="max_threed_amount"
            label="3D max bit amount"
            rules={[{ required: true }]}
          >
            <InputNumber min={100} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="threed_limit"
            label="3D Limit"
            rules={[{ required: true }]}
          >
            <InputNumber min={100} style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
