import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { UpdateDateOnServer, UpdateSingleDataOnServer } from "../../lib/apis";
import {
  USER_DELETE_API,
  USER_PAYMENT_COUNTER_RESET_API,
  USER_UNLOCK_API,
} from "../../lib/config";
import UserCredentialReset from "./credentialReset";
import { moneyFormat } from "../../lib/globle_functions";

const { Text, Paragraph } = Typography;

export default function UserDetailTab({ data, closeDrawer }) {
  const [loading, setLoading] = useState(false);
  const { permissions, access_token, ui_size, id, ...agent } = useSelector(
    (state) => state.user
  );
  const [showEdit, setShowEdit] = useState(false);
  const [showPhone, setShowPhone] = useState(false);

  async function closeEdit(value) {
    setShowEdit(false);

    if (value) {
      closeDrawer();
    }
  }

  async function closePhone(value) {
    setShowPhone(false);
  }

  async function deleteUser(user_id) {
    setLoading(true);
    await UpdateSingleDataOnServer(USER_DELETE_API, user_id, access_token)
      .then(() => {
        closeDrawer(true);
        message.success("User Delete Done");
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.response?.data?.error || "Connection Error");
      });
  }

  async function unlockUser(user_id) {
    setLoading(true);
    await UpdateSingleDataOnServer(USER_UNLOCK_API, user_id, access_token)
      .then(() => {
        closeDrawer(true);
        message.success("User Unlock Done");
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.response?.data?.error || "Connection Error");
      });
  }

  async function userpaymentEditCounterReset(value) {
    setLoading(true);
    await UpdateDateOnServer(
      USER_PAYMENT_COUNTER_RESET_API,
      data.id,
      value,
      access_token
    )
      .then(() => {
        closeDrawer(true);
        message.success("User Payment Edit Counter REset Done");
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.response?.data?.error || "Connection Error");
      });
  }

  return (
    <>
      <Spin spinning={loading}>
        <Row gutter={[8, 8]}>
          <Col span={4}>
            <Text>User Code</Text>
          </Col>
          <Col span={20}>
            <Paragraph copyable>{data.user_code}</Paragraph>
          </Col>
          <Col span={4}>
            <Text>Account</Text>
          </Col>
          <Col span={20}>
            <Paragraph copyable>{data.payment_account}</Paragraph>
          </Col>
          <Col span={4}>
            <Text>Name</Text>
          </Col>
          <Col span={20}>
            <Text>{data.name}</Text>
          </Col>
          <Col span={4}>
            <Text>Login Name</Text>
          </Col>
          <Col span={20}>
            <Text>{data.payment_account}</Text>
          </Col>
          <Col span={4}>
            <Text>Condition</Text>
          </Col>
          <Col span={20}>
            {data.is_deleted ? (
              <Tag color="red">Deleted</Tag>
            ) : data.is_locked ? (
              <Tag color="warning">Locked</Tag>
            ) : (
              <Tag color="success">Normal</Tag>
            )}
          </Col>
          <Col span={4}>
            <Text>Points</Text>
          </Col>
          <Col span={20}>
            <Text>{data.wallet?.point || 0}</Text>
          </Col>
          <Col span={4}>
            <Text>Promo Points</Text>
          </Col>
          <Col span={20}>
            <Text>{data.wallet?.promotion_point}</Text>
          </Col>
          <Col span={4}>
            <Text>Promotion Expire</Text>
          </Col>
          <Col span={20}>
            <Text>{data.wallet?.promotion_point
              ? moment(data.wallet?.promotion_expire).format("YYYY-MM-DD")
              : "---"}</Text>
          </Col>
          <Col span={4}>
            <Text>Total Deposit</Text>
          </Col>
          <Col span={20}>
            <Text>{moneyFormat(data.wallet?.total_deposit || 0)}</Text>
          </Col>
          <Col span={4}>
            <Text>Total Withdraw</Text>
          </Col>
          <Col span={20}>
            <Text>{moneyFormat(data.wallet?.total_withdraw || 0)}</Text>
          </Col>
          <Col span={4}>
            <Text>Withdraw Band Points</Text>
          </Col>
          <Col span={20}>
            <Text>{data.wallet?.withdraw_band_point || 0}</Text>
          </Col>
          <Col span={4}>
            <Text>Update</Text>
          </Col>
          <Col span={20}>
            <Row gutter={12}>
              <Col>
                <Button
                  disabled={data.is_deleted}
                  size={ui_size}
                  onClick={() => {
                    setShowPhone(true);
                  }}
                  type="primary"
                >
                  Update Payment
                </Button>
              </Col>
              <Col>
                <Popconfirm
                  title="Are You Sure Unlock This User ?"
                  onConfirm={() => unlockUser(data.id)}
                  okText="Yes"
                  cancelText="No"
                  disabled={!data.is_locked || data.is_deleted}
                >
                  <Button
                    disabled={!data.is_locked || data.is_deleted}
                    size={ui_size}
                    type="dashed"
                  >
                    Unlock
                  </Button>
                </Popconfirm>
              </Col>
              <Col>
                <Button
                  size={ui_size}
                  disabled={data.is_deleted}
                  onClick={() => {
                    setShowEdit(true);
                  }}
                  type="primary"
                >
                  Reset Password
                </Button>
              </Col>
              <Col>
                <Popconfirm
                  title="Are You Sure Delete This User ?"
                  description="Delete can't recover"
                  disabled={data.is_deleted}
                  onConfirm={() => deleteUser(data.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    disabled={data.is_deleted}
                    size={ui_size}
                    type="primary"
                    danger
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
      <Modal
        open={showEdit}
        onCancel={() => closeEdit()}
        destroyOnClose
        footer={null}
        title="Credential Reset"
      >
        <UserCredentialReset edit={data} closeModel={closeEdit} />
      </Modal>
      <Modal
        open={showPhone}
        onCancel={() => {
          closePhone();
        }}
        destroyOnClose
        footer={null}
        title="User Bank Account Number Update"
      >
        <Form
          onFinish={userpaymentEditCounterReset}
          initialValues={{ payment_account: data.payment_account }}
        >
          <Form.Item
            name="payment_account"
            label="Update"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
