import { Button, Col, Form, Input, Row, Upload, message } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  CmsLoginNameCheck,
  CreateDataOnServer,
  UpdateDateOnServer,
} from "../../lib/apis";
import {
  CMS_AGENT_AGENT_CODE_CHECK_API,
  CMS_AGENT_CREATE_API,
  CMS_AGENT_EDIT_API,
  CMS_AGENT_LOGIN_NAME_CHECK_API,
} from "../../lib/config";
import TextArea from "antd/es/input/TextArea";
import {
  beforeUpload,
  uploadButton,
  uploadImages,
} from "../../lib/globle_functions";

export default function CmsAgentForm({ edit, closeDrawer }) {
  const { ui_size, access_token } = useSelector((state) => state.user);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [profile_url, setProfile_url] = useState("");

  async function submitHandler(value) {
    if (edit) {
      await UpdateDateOnServer(
        CMS_AGENT_EDIT_API,
        edit.id,
        { ...value, profile_url, is_api_only: false },
        access_token
      )
        .then(() => {
          setLoading(false);
          closeDrawer(true);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.error || "Connection Error");
        });
    } else {
      await CreateDataOnServer(
        CMS_AGENT_CREATE_API,
        { ...value, profile_url, is_api_only: false },
        access_token
      )
        .then(() => {
          setLoading(false);
          closeDrawer(true);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.error || "Connection Error");
        });
    }
  }

  return (
    <Form
      variant="filled"
      form={form}
      size={ui_size}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      name="cms_agent_form"
      onFinish={submitHandler}
      initialValues={edit || {}}
    >
      <Row justify={"end"}>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item name="phone" label="Phone No">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          {!edit && (
            <>
              <Form.Item
                name="login_name"
                label="User Name"
                validateDebounce={1000}
                rules={[
                  {
                    required: true,
                    message: "Please Input your User Name",
                  },
                  {
                    async validator(_, value) {
                      return await CmsLoginNameCheck(
                        CMS_AGENT_LOGIN_NAME_CHECK_API,
                        value,
                        access_token
                      )
                        .then(({ data: { avilable } }) =>
                          avilable
                            ? Promise.resolve()
                            : Promise.reject("This User is Already Exist")
                        )
                        .catch((error) => {
                          return Promise.reject("This User is Already Exist");
                        });
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: "Password is Required" }]}
              >
                <Input type="password" />
              </Form.Item>
              <Form.Item
                name="agent_code"
                label="agent_code"
                validateDebounce={1000}
                rules={[
                  {
                    required: true,
                    message: "Please Input Agent Code",
                  },
                  {
                    async validator(_, value) {
                      return await CmsLoginNameCheck(
                        CMS_AGENT_AGENT_CODE_CHECK_API,
                        value,
                        access_token
                      )
                        .then(({ data: { avilable } }) =>
                          avilable
                            ? Promise.resolve()
                            : Promise.reject("This AgentCode is Already Exist")
                        )
                        .catch((error) => {
                          return Promise.reject(
                            "This AgentCode is Already Exist"
                          );
                        });
                    },
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    form.setFieldValue(
                      "agent_code",
                      e.target.value.toUpperCase()
                    );
                  }}
                />
              </Form.Item>
            </>
          )}
        </Col>
        <Col span={24}></Col>
        <Col>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
