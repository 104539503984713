import { Button, Col, Form, Input, Row, message } from "antd";
import { CMS_AGENT_CREDENTIAL_RESET_API } from "../../lib/config";
import { UpdateDateOnServer } from "../../lib/apis";
import { useSelector } from "react-redux";
import { useState } from "react";

export default function AgentCredentialReset({ edit, closeModel }) {
  const { access_token, ui_size } = useSelector((state) => state.user);
  const [passwordLoading, setPasswordLoading] = useState(false);
  async function submitHandler(value) {
    setPasswordLoading(true);
    await UpdateDateOnServer(
      CMS_AGENT_CREDENTIAL_RESET_API,
      edit.id,
      value,
      access_token
    )
      .then(() => {
        setPasswordLoading(false);
        message.success("Password reset done");
        closeModel(true);
      })
      .catch((error) => {
        setPasswordLoading(false);
        message.error(error.response?.data?.error || "Connection Error");
      });
  }

  return (
    <Form
      size={ui_size}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={submitHandler}
      name="credential_reset"
      initialValues={{ login_name: edit.login_name, Password: "" }}
    >
      <Form.Item name="login_name" label="Login Name">
        <Input disabled />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: "Please Input Password",
          },
        ]}
      >
        <Input type="password" />
      </Form.Item>
      <Row gutter={[16, 16]} justify={"end"}>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              closeModel();
            }}
          >
            Cancel
          </Button>
        </Col>
        <Col>
          <Form.Item>
            <Button loading={passwordLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
