import { Button, Col, DatePicker, Form, Row, message } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { CreateDataOnServer, UpdateDateOnServer } from "../../lib/apis";
import {
  GAME_TWOD_THREED_THREED_ONGOING_CREATE_API,
  GAME_TWOD_THREED_THREED_ONGOING_UPDATE_API,
} from "../../lib/config";
import dayjs from "dayjs";

export default function ThreedOngoingForm({ edit, closeDrawer }) {
  const { ui_size, access_token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  async function submitHandler(value) {
    if (edit) {
      setLoading(true);
      await UpdateDateOnServer(
        GAME_TWOD_THREED_THREED_ONGOING_UPDATE_API,
        edit.id,
        value,
        access_token
      )
        .then(() => {
          setLoading(false);
          closeDrawer(true);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.error || "Connection Error");
        });
    } else {
      setLoading(true);
      await CreateDataOnServer(
        GAME_TWOD_THREED_THREED_ONGOING_CREATE_API,
        value,
        access_token
      )
        .then(() => {
          setLoading(false);
          closeDrawer(true);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.error || "Connection Error");
        });
    }
  }

  return (
    <Form
      size={ui_size}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      name="threed_ongoing_form"
      initialValues={
        edit ? {
              target_date:dayjs(edit.target_date),
              bit_start_date: dayjs(edit.bit_start_date),
              bit_end_date: dayjs(edit.bit_end_date),
            }
          : {}
      }
      onFinish={submitHandler}
    >
      <Row justify={"end"}>
        <Col span={24}>
          <Form.Item
            name="target_date"
            label="3D Release Date"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="bit_start_date"
            label="User can Bit At"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="bit_end_date"
            label="User Stop Bit At"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
