import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetDataListFromServer, UpdateSingleDataOnServer } from "../lib/apis";
import { Button, Col, Drawer, Popconfirm, Row, Table, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  AGENT_PAYMENT_METHOD_LIST_API,
  AGENT_PAYMENT_METHOD_DELETE_API,
} from "../lib/config";
import { QUERY_AGENT_PAYMENT_METHOD } from "../lib/query_objects";
import { queryHandler } from "../lib/globle_functions";
import AgentPaymentMethodForm from "../components/agent_payment_method/form";

export default function AgentPaymentMehtodPage() {
  const { permissions, access_token, ui_size } = useSelector(
    (state) => state.user
  );
  const [list, setList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });
  const [filter, setFilter] = useState({
    name: "",
    is_deleted: "false",
    order_by: "created_at",
    order_type: "DESC",
  });
  
  async function getDataList() {
    setLoading(true);
    await GetDataListFromServer(
      AGENT_PAYMENT_METHOD_LIST_API,
      queryHandler(filter, QUERY_AGENT_PAYMENT_METHOD, pagin),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setPagin({ ...pagin, total });
        setList(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function paymentMethodDelete(id) {
    setLoading(true);
    await UpdateSingleDataOnServer(
      AGENT_PAYMENT_METHOD_DELETE_API,
      id,
      access_token
    )
      .then(() => {
        getDataList();
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getDataList();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Account Type",
      dataIndex: "account_type",
    },
    {
      title: "Account No",
      dataIndex: "account_no",
    },
    {
      title: "",
      render: (_, user) => (
        <Row gutter={16} justify={"end"}>
          <Col>
            <Popconfirm
              title="Are You Sure Delete This Payment Method ?"
              description="Delete can't recover"
              onConfirm={() => paymentMethodDelete(user.id)}
              okText="Yes"
              cancelText="No"
              disabled={!permissions.includes("IS_AGENT")}
            >
              <Button
                disabled={!permissions.includes("IS_AGENT")}
                size={ui_size}
                type="primary"
                danger
              >
                Delete
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  function closeDrawer(key) {
    if (key) {
      getDataList();
    }
    setShowForm(false);
  }

  return (
    <>
      <Row gutter={[0, 16]} justify={"end"}>
        <Col>
          {permissions.includes("IS_AGENT") && (
            <Button
              size={ui_size}
              type="primary"
              onClick={() => setShowForm(true)}
              icon={<PlusOutlined />}
            >
              New Payment Method
            </Button>
          )}
        </Col>
        <Col span={24}>
          <Table
            size={ui_size}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={list}
            loading={loading}
          />
        </Col>
      </Row>
      <Drawer
        open={showForm}
        onClose={() => closeDrawer()}
        title="Create New Payment Method"
        destroyOnClose
        placement="right"
        width={"600px"}
      >
        <AgentPaymentMethodForm closeDrawer={closeDrawer} />
      </Drawer>
    </>
  );
}
