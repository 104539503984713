import React, { useState, useEffect } from "react";
import { GetDataListFromServer } from "../../lib/apis";
import { AGENT_USER_BALL_MAUNG } from "../../lib/config";
import { QUERY_AGENT_USER_BALL_MAUNG } from "../../lib/query_objects";
import { queryHandler } from "../../lib/globle_functions";
import { useSelector } from "react-redux";
import {
  message,
  Tag,
  Typography,
  Button,
  Table,
  Drawer,
  Form,
  Input,
  Row,
  DatePicker,
} from "antd";
import moment from "moment";
import BallMaungDetail from "./ball_maung_detail";

const { Text, Title } = Typography;

const BallMaung = ({ data }) => {
  const userId = data?.id;

  const { access_token } = useSelector((state) => state.user);
  const [ballMaungList, setBallMaungList] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });

  const [filter, setFilter] = useState({
    id: "",
    order_by: "created_at",
    order_type: "DESC",
  });

  const getDataList = async (page_number, per_page) => {
    setLoading(true);
    await GetDataListFromServer(
      `${AGENT_USER_BALL_MAUNG}/${userId}`,
      queryHandler(filter, QUERY_AGENT_USER_BALL_MAUNG, {
        page_number,
        per_page,
      }, 'alternative'),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setPagin({ ...pagin, total, page_number, per_page });
        setBallMaungList(result);
        console.log("maung detail", result)
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response.data?.error || "Connection Error");
      });
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  const tableData = ballMaungList.map((item, index) => ({
    key: item.id,
    user_id: item.id,
    account : item.win_cut_percent,
    bet_time: item.created_at,
    bit_amount: item.bit_amount,
    is_finish: item.is_finish,
    is_win: item.is_win,
    final_point: item.final_point,
    updated_at: item.updated_at,
  }));

  useEffect(() => {
    getDataList(pagin.page_number, pagin.per_page);
  }, []);

  const columns = [
    {
      title: "Bet Time",
      key: "bet_time",
      dataIndex: "bet_time",
      render: (text, record) => (
        <>
          <Text>{moment(record.bet_time).format("MMM DD YYYY")}</Text>
          <br />
          <Text>{moment(record.bet_time).format("h:mm A")}</Text>
        </>
      ),
    },

    {
      title: "Bit Amount",
      dataIndex: "bit_amount",
    },

    {
      title: "Is Finished?",
      key: "is_finish",
      dataIndex: "is_finish",
      render: (text, record) => {
        const isFinished = record.is_finish;
        const tagColor = isFinished ? "success" : "error";
        const tagText = isFinished ? "Yes" : "No";

        return (
          <Tag color={tagColor} style={{ text }}>
            <Text
              style={{ FontSize: "15px", color: isFinished ? "green" : "red" }}
            >
              {tagText}
            </Text>
          </Tag>
        );
      },
    },
    {
      title: "Is Win?",
      key: "is_win",
      dataIndex: "is_win",
      render: (text, record) => {
        const isFinished = record.is_finish;
        if (!isFinished) {
          return <Text style={{ fontSize: "15px" }}>-</Text>;
        }
        const isWin = record.is_win;
        const tagText = isWin ? "နိုင်" : "ရှုံး";

        return (
          <Text style={{ FontSize: "15px", color: isWin ? "green" : "red" }}>
            {tagText}
          </Text>
        );
      },
    },

    {
      title: "Updated Time",
      key: "updated_time",
      dataIndex: "updated_time",
      render: (text, record) => (
        <>
          <Text>{moment(record.updated_at).format("MMM DD YYYY")}</Text>
          <br />
          <Text>{moment(record.updated_at).format("h:mm A")}</Text>
        </>
      ),
    },

    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Button
          onClick={() => {
            setShowDrawer(true);
            setDetailData(record);
          }}
          type="primary"
        >
          Detail
        </Button>
      ),
    },
  ];

  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{ marginBottom: "20px" }}
      >
        <Title level={5} style={{ marginTop: "5px" }}>
          Maung History
        </Title>
        <Form layout="inline" clear>
          <Form.Item name="endDate">
            <DatePicker
              placeholder="Select Updated Date"
              style={{ width: 200 }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary">Clear</Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary">Search</Button>
          </Form.Item>
        </Form>
      </Row>
      <Table
        style={{ marginTop: "20px" }}
        columns={columns}
        dataSource={tableData}
        rowKey="key"
        pagination={{
          total: pagin.total,
          defaultCurrent: 1,
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 30, 50],
          onChange: (pageNumber, perPage) => {
            setPagin({ ...pagin });
            getDataList(pageNumber, perPage);
          },
        }}
        loading={loading}
      />

      <Drawer
        open={showDrawer}
        onClose={() => closeDrawer()}
        title="အသေးစိတ် အချက်လက်များ"
        destroyOnClose
        placement="right"
        width={560}
      >
        <BallMaungDetail data={detailData} closeDrawer={closeDrawer} />
      </Drawer>
    </>
  );
};

export default BallMaung;
