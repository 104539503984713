import { Button, Col, message, Row, Tag, Typography } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { UpdateSingleDataOnServer } from "../../lib/apis";
import {
  GAME_BALL_AGENT_UPDATE_API,
  GAME_PP_AGENT_UPDATE_API,
  GAME_SHAN_AGENT_UPDATE_API,
} from "../../lib/config";

const { Text } = Typography;

export default function AgentGames({ data, closeDrawer }) {
  const { ui_size, access_token, permissions } = useSelector(
    (state) => state.user
  );
  const [loading, setLoading] = useState(false);

  async function ballUpdate() {
    setLoading(true);
    await UpdateSingleDataOnServer(
      GAME_BALL_AGENT_UPDATE_API,
      data.id,
      access_token
    )
      .then(() => {
        setLoading(false);
        closeDrawer(true);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function ppUpdate() {
    setLoading(true);
    await UpdateSingleDataOnServer(
      GAME_PP_AGENT_UPDATE_API,
      data.id,
      access_token
    )
      .then(() => {
        setLoading(false);
        closeDrawer(true);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function shanUpdate() {
    setLoading(true);
    await UpdateSingleDataOnServer(
      GAME_SHAN_AGENT_UPDATE_API,
      data.id,
      access_token
    )
      .then(() => {
        setLoading(false);
        closeDrawer(true);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[24, 12]} align={"middle"}>
          {/* <Col span={4}>
            <Text>2D / 3D</Text>
          </Col>
          <Col span={4}>
            {data?.games?.includes("TWOD_THREED") ? (
              <Tag color="green">Access Grant</Tag>
            ) : (
              <Tag color="red">Access Denied</Tag>
            )}
          </Col>
          <Col>
            <Button disabled={data.is_deleted} style={{width:'100px'}} size={ui_size} type="primary">
              {data?.games?.includes("TWOD_THREED") ? "Remove" : "Add"}
            </Button>
          </Col>
          <Col span={24}></Col> */}
          <Col span={4}>
            <Text>Ball</Text>
          </Col>
          <Col span={4}>
            {data?.games?.includes("BALL") ? (
              <Tag color="green">Access Grant</Tag>
            ) : (
              <Tag color="red">Access Denied</Tag>
            )}
          </Col>
          <Col>
            <Button
              onClick={() => ballUpdate()}
              disabled={data.is_deleted || !permissions.includes("BALL_CREATE")}
              style={{ width: "100px" }}
              size={ui_size}
              type="primary"
            >
              {data?.games?.includes("BALL") ? "Remove" : "Add"}
            </Button>
          </Col>
          <Col span={24}></Col>
          <Col span={4}>
            <Text>Shan</Text>
          </Col>
          <Col span={4}>
            {data?.games?.includes("SHAN") ? (
              <Tag color="green">Access Grant</Tag>
            ) : (
              <Tag color="red">Access Denied</Tag>
            )}
          </Col>
          <Col>
            <Button
              onClick={() => shanUpdate()}
              disabled={data.is_deleted || !permissions.includes("SHAN_CREATE")}
              style={{ width: "100px" }}
              size={ui_size}
              type="primary"
            >
              {data?.games?.includes("SHAN") ? "Remove" : "Add"}
            </Button>
          </Col>
          <Col span={24}></Col>
          <Col span={4}>
            <Text>PP ( Slot, Live Casino )</Text>
          </Col>
          <Col span={4}>
            {data?.games?.includes("PP") ? (
              <Tag color="green">Access Grant</Tag>
            ) : (
              <Tag color="red">Access Denied</Tag>
            )}
          </Col>
          <Col>
            <Button
              onClick={() => ppUpdate()}
              disabled={data.is_deleted || !permissions.includes("PP_CREATE")}
              style={{ width: "100px" }}
              size={ui_size}
              type="primary"
            >
              {data?.games?.includes("PP") ? "Remove" : "Add"}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
