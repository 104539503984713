import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  DeleteDataOnServer,
  GetDataListFromServer,
  UpdateSingleDataOnServer,
} from "../lib/apis";
import {
  Button,
  Col,
  Drawer,
  Modal,
  Popconfirm,
  Row,
  Table,
  Tag,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CmsUserForm from "../components/cms/form";
import {
  CMS_USER_DELETE,
  CMS_USER_GET_LIST,
  CMS_USER_UNLOCK_API,
} from "../lib/config";
import { QUERY_CMS_USER } from "../lib/query_objects";
import { queryHandler } from "../lib/globle_functions";
import CredentialReset from "../components/cms/credential";

export default function CmsUserPage() {
  const { permissions, access_token, ui_size } = useSelector(
    (state) => state.user
  );
  const [list, setList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });
  const [filter, setFilter] = useState({
    name: "",
    id: "",
    is_locked: "",
    is_deleted: "false",
    order_by: "created_at",
    order_type: "DESC",
  });

  async function getDataList() {
    setLoading(true);
    await GetDataListFromServer(
      CMS_USER_GET_LIST,
      queryHandler(filter, QUERY_CMS_USER, pagin),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setPagin({ ...pagin, total });
        setList(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function unlockUser(id) {
    setLoading(true);
    await UpdateSingleDataOnServer(CMS_USER_UNLOCK_API, id, access_token)
      .then(() => {
        getDataList();
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function closeModel(value) {
    if (value) {
      getDataList();
    }
    setEdit(false);
    setShowModel(false);
  }

  async function cmsUserDelete(id) {
    setLoading(true);
    await DeleteDataOnServer(CMS_USER_DELETE, id, access_token)
      .then(() => {
        getDataList();
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getDataList();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Login Name",
      dataIndex: "login_name",
    },
    {
      title: "Permission Level",
      dataIndex: ["access_permissions", "name"],
    },
    {
      title: "User is Locked",
      dataIndex: "is_locked",
      render: (is_locked) => (
        <Tag color={is_locked ? "red" : "green"}>
          {is_locked ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phone_no",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "",
      render: (_, user) => (
        <Row gutter={16} justify={"end"}>
          <Col>
            <Popconfirm
              title="Are You Sure Unlock This User ?"
              onConfirm={() => unlockUser(user.id)}
              okText="Yes"
              cancelText="No"
              disabled={!permissions.includes("CMS_USER_UPDATE")}
            >
              <Button
                disabled={
                  !user.is_locked || !permissions.includes("CMS_USER_UPDATE")
                }
                size={ui_size}
                onClick={() => unlockUser(user.id)}
                type="dashed"
              >
                Unlock
              </Button>
            </Popconfirm>
          </Col>
          <Col>
            <Button
              disabled={!permissions.includes("CMS_USER_CREDENTIAL_RESET")}
              size={ui_size}
              onClick={() => {
                setEdit(user);
                setShowModel(true);
              }}
              type="primary"
            >
              Reset Password
            </Button>
          </Col>
          <Col>
            <Button
              size={ui_size}
              disabled={!permissions.includes("CMS_USER_UPDATE")}
              type="primary"
              onClick={() => {
                setEdit(user);
                setShowForm(true);
              }}
            >
              Edit
            </Button>
          </Col>
          <Col>
            <Popconfirm
              title="Are You Sure Delete This User ?"
              description="Delete can't recover"
              onConfirm={() => cmsUserDelete(user.id)}
              okText="Yes"
              cancelText="No"
              disabled={!permissions.includes("CMS_USER_DELETE")}
            >
              <Button
                disabled={!permissions.includes("CMS_USER_DELETE")}
                size={ui_size}
                type="primary"
                danger
              >
                Delete
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  function closeDrawer(key) {
    if (key) {
      getDataList();
    }
    setEdit(false);
    setShowForm(false);
  }

  return (
    <>
      <Row gutter={[0, 16]} justify={"end"}>
        <Col>
          {permissions.includes("CMS_USER_CREATE") && (
            <Button
              size={ui_size}
              type="primary"
              onClick={() => setShowForm(true)}
              icon={<PlusOutlined />}
            >
              New User
            </Button>
          )}
        </Col>
        <Col span={24}>
          <Table
            size={ui_size}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={list}
            loading={loading}
          />
        </Col>
      </Row>
      <Drawer
        open={showForm}
        onClose={() => closeDrawer()}
        title={edit ? "Edit User" : "New User"}
        destroyOnClose
        placement="right"
        width={"800px"}
      >
        <CmsUserForm edit={edit} closeDrawer={closeDrawer} />
      </Drawer>
      <Modal
        open={showModel}
        onCancel={() => closeModel()}
        destroyOnClose
        footer={null}
        title="Credential Reset"
      >
        <CredentialReset edit={edit} closeModel={closeModel} />
      </Modal>
    </>
  );
}
