import { Button, Col, Form, Input, Row, Select, message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  CmsLoginNameCheck,
  CreateDataOnServer,
  GetDataListFromServer,
  UpdateDateOnServer,
} from "../../lib/apis";
import {
  CMS_ACCESS_LEVEL_API,
  CMS_USER_CREATE,
  CMS_USER_EDIT_API,
  CMS_USER_LOGIN_NAME_CHECK,
} from "../../lib/config";
import { queryHandler } from "../../lib/globle_functions";
import { QUERY_ACCESS_PERMISSION } from "../../lib/query_objects";

export default function CmsUserForm({ edit, closeDrawer }) {
  const { ui_size, access_token } = useSelector((state) => state.user);
  const [accessLevels, setAccessLevels] = useState([]);
  const [form] = Form.useForm();
  useEffect(() => {
    setLoading(true);
    GetDataListFromServer(
      CMS_ACCESS_LEVEL_API,
      queryHandler(
        { name: "", order_by: "created_at", order_type: "DESC" },
        QUERY_ACCESS_PERMISSION,
        { page_number: 1, per_page: 1000 }
      ),
      access_token
    ).then(({ data: { data: result, total } }) => {
      setAccessLevels(result);
      setLoading(false);
    });
  }, []);
  const [loading, setLoading] = useState(false);

  async function submitHandler(value) {
    setLoading(true);
    if (edit) {
      await UpdateDateOnServer(CMS_USER_EDIT_API, edit.id, value, access_token)
        .then(() => {
          setLoading(false);
          closeDrawer(true);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.error || "Connection Error");
        });
    } else {
      await CreateDataOnServer(CMS_USER_CREATE, value, access_token)
        .then(() => {
          setLoading(false);
          closeDrawer(true);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.error || "Connection Error");
        });
    }
  }

  return (
    <Form
      variant="filled"
      form={form}
      size={ui_size}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      name="cms_user_form"
      onFinish={submitHandler}
      initialValues={edit || {}}
    >
      <Row justify={"end"}>
        <Col span={12}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
        </Col>
        <Col span={12}>
          {!edit && (
            <>
              <Form.Item
                name="login_name"
                label="User Name"
                validateDebounce={2000}
                rules={[
                  {
                    required: true,
                    message: "Please Input your User Name",
                  },
                  {
                    async validator(_, value) {
                      return await CmsLoginNameCheck(
                        CMS_USER_LOGIN_NAME_CHECK,
                        value,
                        access_token
                      )
                        .then(({ data: { avilable } }) =>
                          avilable
                            ? Promise.resolve()
                            : Promise.reject("This User is Already Exist")
                        )
                        .catch((error) => {
                          return Promise.reject("This User is Already Exist");
                        });
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: "Password is Required" }]}
              >
                <Input type="password" />
              </Form.Item>
            </>
          )}
        </Col>
        <Col span={24}>
          <Form.Item
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            name="phone_no"
            label="Phone No"
          >
            <Input />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            name="access_lvl_id"
            label="Access Level"
          >
            <Select
              style={{ width: "100%" }}
              options={accessLevels.map((value) => ({
                label: value.name,
                value: value.id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
