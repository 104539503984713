import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetDataListFromServer } from "../../lib/apis";
import { GAME_TWOD_THREED_THREED_USER_LIST_APi } from "../../lib/config";
import { Col, message, Row, Table } from "antd";

export default function ThreedDetailPage({ data, closeDrawer }) {
  const { access_token, id, ui_size, ...agent } = useSelector(
    (state) => state.user
  );
  const [list, setList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });
  const [filter, setFilter] = useState({
    user_code: "",
  });

  function numberHandler(key) {
    switch (true) {
      case key < 10:
        return `00${key}`;
        break;
      case key < 100:
        return `0${key}`;
        break;
      default:
        return `${key}`;
        break;
    }
  }

  async function getDataList(page_number, per_page) {
    let queryString = `page_number=${page_number}&per_page=${per_page}&threed_ongoing_id=${
      data.ongoing_id
    }&order_by=created_at&order_type=DESC&number=${numberHandler(data.key)}`;

    setLoading(false);
    await GetDataListFromServer(
      GAME_TWOD_THREED_THREED_USER_LIST_APi,
      queryString,
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setPagin({ ...pagin, total, page_number, per_page });
        setList(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getDataList(pagin.page_number, pagin.per_page);
  }, []);

  const columns = [
    {
      title: "User Code",
      dataIndex: ["user", "user_code"],
    },
    {
      title: "Bit Amount",
      dataIndex: "bit_amount",
    },
    {
      title: "bit Wallet",
      dataIndex: "bit_wallet",
    },
    {
      title:'win Amount',
      dataIndex:''
    },
    {
      title: "win_number",
      dataIndex: "win_number",
      render: (win_number) =>
        win_number === "00" ? "Not_finished" : win_number,
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Table
          size={ui_size}
          columns={columns}
          rowKey={(record) => record.id}
          pagination={{
            total: pagin.total,
            defaultCurrent: 1,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 30, 50],
            onChange: (pageNumber, perPage) => {
              getDataList(pageNumber, perPage);
            },
          }}
          dataSource={list}
          loading={loading}
        />
      </Col>
    </Row>
  );
}
