import { Button, Col, Form, Input, Row, Upload, message } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { CreateDataOnServer, UpdateDateOnServer } from "../../lib/apis";
import { GAME_BALL_TEAM_CREATE_API, GAME_BALL_TEAM_UPDATE_API } from "../../lib/config";
import {
  beforeUpload,
  uploadButton,
  uploadImages,
} from "../../lib/globle_functions";

export default function BallTeamCreateForm({ edit, closeDrawer }) {
  const { ui_size, access_token } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [logo_url, setLogoUrl] = useState(edit ? edit.logo : "");
  const [form] = Form.useForm();

  async function submitHandler(value) {
    if (!logo_url || logo_url === "") {
      message.error("Logo is required");
    }
    if (edit) {
      setLoading(true)
      await UpdateDateOnServer(
        GAME_BALL_TEAM_UPDATE_API,
        edit.id,
        {
          name_en: value.name_en,
          name_mm: value.name_mm,
          logo: logo_url,
        },
        access_token
      )
        .then(() => {
          setLoading(false);
          closeDrawer(true);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.error || "Connection Error");
        });
    } else {
      setLoading(true);
      await CreateDataOnServer(
        GAME_BALL_TEAM_CREATE_API,
        {
          name_en: value.name_en,
          name_mm: value.name_mm,
          logo: logo_url,
        },
        access_token
      )
        .then(() => {
          setLoading(false);
          closeDrawer(true);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error?.response?.data?.error || "Connection Error");
        });
    }
  }

  return (
    <Form
      variant="filled"
      size={ui_size}
      form={form}
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      name="ball_agent_form"
      onFinish={submitHandler}
      initialValues={edit ? edit : {}}
    >
      <Row justify={"end"}>
        <Col span={12}>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            name="name_en"
            label="Name En"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            name="name_mm"
            label="Name MM"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="logo" label="Logo">
            <Upload
              name="agent_profile"
              accept=".jpg,.jpeg,.png,.gif"
              listType="picture-card"
              showUploadList={false}
              customRequest={(option) =>
                uploadImages({
                  ...option,
                  access_token,
                  folder_name: "images/ball",
                  image: logo_url,
                  setImage: setLogoUrl,
                })
              }
              beforeUpload={(file) => beforeUpload(file, 5)}
              isImageUrl={true}
            >
              {logo_url && logo_url !== "" ? (
                <img
                  src={logo_url}
                  alt="avatar"
                  style={{
                    objectFit: "contain",
                    objectPosition: "center",
                    height: "100px",
                    width: "100px",
                  }}
                />
              ) : (
                uploadButton(loading)
              )}
            </Upload>
          </Form.Item>
        </Col>
        <Col>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
